import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import PersonAdd from "@material-ui/icons/PersonAdd";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import Tooltip from "@material-ui/core/Tooltip";
import { EnhancedTable } from "../../../../components/Table/EnhancedTable";
import { useIncomingDocumentsByDept } from "../hooks/useIncomingDocumentsByDept";
import { IncomingModal } from "./IncomingModal";
import { IncomingAdditionalModal } from "./IncomingAdditionalModal";
import { IncomingReturnModal } from "./IncomingReturnModal";
import { useFiles } from "../hooks/useRetrieveFiles";
import { Context } from "../../../../context/Context";
import { ViewFileModal } from "./ViewFileModal";
import Swal from "sweetalert2";
import { usePrintedProcess } from "../hooks/usePrintedProcess";
import { usePrintedApproved } from "../hooks/usePrintedApproved";

interface Props {
    toDate: string;
    fromDate: string;
    isFilter: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(1),
            width: 10,
            height: 10,
        },
        extendedIcon: {
            marginRight: theme.spacing(1),
        },
        root: {
            width: "100%",
        },
        paper: {
            width: "100%",
            marginBottom: theme.spacing(2),
        },
    })
);

export const IncomingTableByDept: React.FC<Props> = ({ fromDate, toDate, isFilter }) => {
    const { state, dispatch } = useContext(Context);
    const classes = useStyles();
    const { data = [], isLoading } = useIncomingDocumentsByDept({
        fromDate: fromDate,
        toDate: toDate,
        isFilter: isFilter,
    });
    const {
        data: { data: dataFiles = [] },
    }: any = useFiles();
    const [open, setOpen] = useState<boolean>(false);
    const [openFile, setFileOpen] = useState<boolean>(false);
    const [additionalOpen, setAdditionalOpen] = useState<boolean>(false);
    const [returnOpen, setReturnOpen] = useState<boolean>(false);
    const [isPrint, setPrint] = useState<any>(false);
    const [id, setId] = useState({
        id: "",
        document_id: "",
        userid: 0,
        username: "",
    });
    const [document_id, setDocumentId] = useState<any>("");
    const [return_id, setReturnId] = useState<any>("");
    const [process] = usePrintedProcess();
    const [create] = usePrintedApproved();
    useEffect(() => {
        if (isLoading === false) {
            dispatch({
                type: "COUNT_INCOMINGBYDEPT_ACTION",
                count_incoming_dept: data.length,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    const columns: any = [
        {
            Header: "Reference No",
            accessor: "reference_no",
            width: 200,
        },
        {
            Header: "Source Office",
            accessor: "source",
            width: 200,
        },
        {
            Header: "Type",
            accessor: "document_type",
            width: 90,
        },
        {
            Header: "Category",
            accessor: "category",
            width: 90,
        },
        {
            Header: "Purpose/Action Required",
            accessor: "purpose",
            width: 150,
        },
        {
            Header: "Subject Matter",
            accessor: "subject_matter",
            width: 180,
        },

        {
            Header: "Document Date",
            accessor: "createdAt",
            Cell: (row: any) => <>{moment(row.cell.value).format("MM/DD/YYYY hh:mm a")}</>,
            width: 120,
        },
        {
            Header: "Received By",
            accessor: "received_by",
            Cell: (row: any) => (
                <>{row.cell.row.original.list_route?.name ? row.cell.row.original.list_route.name : ""}</>
            ),
        },
        {
            Header: "File",
            accessor: "attach_name",
            Cell: (row: any) => {
                const filter = dataFiles.filter((data) => data.document_id === row.cell.row.original.id);
                return (
                    <div>
                        {filter.map((item: any, index: number) => {
                            return (
                                <p key={index} style={{ color: "red" }}>
                                    {item.attach_name ? item.attach_name : "No File"}
                                </p>
                            );
                        })}
                    </div>
                );
            },
        },
        {
            Header: "Remarks",
            accessor: "",
            Cell: (row: any) => (
                <>
                    <p style={{ color: "red" }}>
                        {row.cell.row.original.list_route.remarks ? row.cell.row.original.list_route.remarks : ""}
                    </p>
                </>
            ),
            width: 100,
        },
        {
            Header: "Status",
            accessor: "status",
            width: 120,
            Cell: (row: any) => (
                <>
                    <p style={{ color: "red" }}>{row.cell.row.original.list_route.status}</p>
                </>
            ),
        },
        {
            headerClassName: "correspondence-action",
            Header: "Action",
            accessor: "action",
            Cell: (row: any) => {
                const filter = dataFiles.filter(
                    (data) => data.document_id === row.cell.row.original.id && data.attach_name !== null
                );
                return (
                    <div>
                        <Tooltip title="Add">
                            <span>
                                <IconButton
                                    ria-label="add"
                                    className={classes.margin}
                                    onClick={() => {
                                        handleAddIcon(row.cell.row.original.id);
                                    }}
                                    disabled={
                                        row.cell.row.original.list_route.status === "Returned" ||
                                        row.cell.row.original.status === "Approved" ||
                                        row.cell.row.original.status === "Released" ||
                                        row.cell.row.original.list_route.status === "Not yet acknowledged"
                                            ? true
                                            : false
                                    }
                                >
                                    <PersonAdd fontSize="small" />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title="Download">
                            <IconButton
                                ria-label="download"
                                className={classes.margin}
                                onClick={() => {
                                    handleViewFiles(row.cell.row.original.id, filter.length);
                                }}
                                disabled={
                                    row.cell.row.original.status === "Approved" ||
                                    row.cell.row.original.status === "Released"
                                        ? true
                                        : false
                                }
                            >
                                <CloudDownloadIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Approved">
                            <span>
                                <IconButton
                                    ria-label="approved"
                                    className={classes.margin}
                                    onClick={() => {
                                        handleApprovedIcon(
                                            row.cell.row.original.list_route.id,
                                            row.cell.row.original.id,
                                            filter.length
                                        );
                                    }}
                                    disabled={
                                        row.cell.row.original.list_route.status === "Not yet acknowledged" ||
                                        row.cell.row.original.list_route.status === "Returned" ||
                                        row.cell.row.original.list_route.status ===
                                            "Resubmission not yet acknowledge" ||
                                        row.cell.row.original.status === "Approved" ||
                                        row.cell.row.original.status === "Released"
                                            ? true
                                            : false
                                    }
                                >
                                    <CheckCircleIcon fontSize="small" />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title="Return">
                            <span>
                                <IconButton
                                    ria-label="return"
                                    className={classes.margin}
                                    onClick={() => {
                                        handleReturnIcon(row.cell.row.original.list_route.id, filter.length);
                                    }}
                                    disabled={
                                        row.cell.row.original.list_route.status === "Returned" ||
                                        row.cell.row.original.status === "Approved" ||
                                        row.cell.row.original.status === "Released" ||
                                        row.cell.row.original.list_route.status === "Not yet acknowledged"
                                            ? true
                                            : false
                                    }
                                >
                                    <AutorenewIcon fontSize="small" />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </div>
                );
            },
            width: 200,
        },
    ];

    const handleAddIcon = async (document_id: any) => {
        setDocumentId(document_id);
        setAdditionalOpen(true);
    };

    const handleApprovedIcon = (id: string, document_id: any, file_count: number) => {
        setId({
            id: id,
            document_id: document_id,
            userid: state.employee_id,
            username: state.full_name,
        });
        if (file_count) {
            setOpen(true);
        } else {
            Swal.fire({
                title: "No uploaded file!",
                text: "Please check hard copy.",
                icon: "info",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, release it!",
                customClass: {
                    container: "view_modal",
                },
            }).then(async (result) => {
                if (result.isConfirmed) {
                    create.mutate({
                        id: id,
                        document_id: document_id,
                        userid: state.employee_id,
                        username: state.full_name,
                    });
                }
            });
        }
    };

    const handleReturnIcon = (id: string, file_count: number) => {
        setReturnId(id);
        setReturnOpen(true);

        if (file_count) {
            setPrint(false);
        } else {
            setPrint(true);
        }
    };

    if (isLoading) {
        return <span>Loading</span>;
    }

    const handleViewFiles = (item_id: string, file_count: number) => {
        setId({ ...id, id: item_id });
        if (file_count) {
            setFileOpen(true);
        } else {
            Swal.fire({
                title: "No uploaded file!",
                text: "Please check hard copy.",
                icon: "info",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, release it!",
                customClass: {
                    container: "view_modal",
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    process.mutate({ id: item_id });
                }
            });
        }
    };

    return (
        <div className={classes.root}>
            <EnhancedTable columns={columns} data={data} view={true} />
            {id ? <IncomingModal open={open} setOpen={setOpen} data={id} /> : ""}
            {id ? <ViewFileModal openFile={openFile} setFileOpen={setFileOpen} id={id.id} /> : ""}
            {document_id ? (
                <IncomingAdditionalModal
                    document_id={document_id}
                    additionalOpen={additionalOpen}
                    setAdditionalOpen={setAdditionalOpen}
                    isDept={true}
                />
            ) : (
                ""
            )}

            {return_id ? (
                <IncomingReturnModal
                    id={return_id}
                    returnOpen={returnOpen}
                    setReturnOpen={setReturnOpen}
                    isPrint={isPrint}
                />
            ) : (
                ""
            )}
        </div>
    );
};

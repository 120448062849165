import React from "react";
import { Container, Col, Card, CardBody, Row } from "reactstrap";

import { UserTable } from "./views/UserTable";

export const Admin: React.FC = () => {
  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">{"Admin Assignment"}</h3>
        </Col>
      </Row>
      <Row>
        <Card>
          <CardBody>
            <div className="tabs tabs--bordered-bottom">
              <UserTable />
            </div>
          </CardBody>
        </Card>
      </Row>
    </Container>
  );
};

import React, { useState } from "react";
import classNames from "classnames";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

import { CategoryTable } from "./CategoryTable";
import { TypeTable } from "./TypeTable";
import { ActionTable } from "./ActionTable";

export const ManagerTabs: React.FC = () => {
  const [activeTab, setActiveTab] = useState("1");
  const toggle = ({ tab }: { tab: any }) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="tabs__wrap">
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classNames({ active: activeTab === "1" })}
            onClick={() => toggle({ tab: "1" })}
          >
            Category
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames({ active: activeTab === "2" })}
            onClick={() => toggle({ tab: "2" })}
          >
            Type
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames({ active: activeTab === "3" })}
            onClick={() => toggle({ tab: "3" })}
          >
            Action
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <CategoryTable />
        </TabPane>
        <TabPane tabId="2">
          <TypeTable />
        </TabPane>
        <TabPane tabId="3">
          <ActionTable />
        </TabPane>
      </TabContent>
    </div>
  );
};

import React, { useContext } from "react";
import SidebarLink from "./SidebarLink";
import SidebarCategory from "./SidebarCategory";
import { Context } from "../../../context/Context";
import { Button, Row } from "reactstrap";
import PrintIcon from "@material-ui/icons/Print";
import UserManual from "../../../shared/docs/UserManual.pdf";
interface Props {
    onClick: () => void;
}

export const SidebarContent: React.FC<Props> = ({ onClick }) => {
    const hideSidebar = () => {
        onClick();
    };
    const { state } = useContext(Context);
    const TotalPendingIncoming = state.count_incoming_dept + state.count_incoming;
    const TotalPendingApproved = state.count_approved_dept + state.count_approved;
    const TotalPendingArchived = state.count_archived + state.count_archived_dept;
    const TotalPendingOutgoing = state.count_outgoing + state.count_outgoing_dept;
    const TotalReturnOutgoing = state.count_outgoing_return + state.count_outgoing_return_dept;
    const TotalNYAOutgoing = state.count_outgoing_pending + state.count_outgoing_pending_dept;
    const handleEvalOpen = () => {
        window.open(
            "https://docs.google.com/forms/d/e/1FAIpQLSc_6r36PxqcPvV4mJ1aK8oICLc1WbhohPlRjwIsTDVpJrCeRA/viewform?fbclid=IwAR2Up01379jy3ujctVBgvv9vqgesIej5lpW5XRI4f4976uTKiPgpsYSvb_E"
        );
    };
    const handleUserManualOpen = () => {
        window.open(UserManual);
    };
    return (
        <div className="sidebar__content">
            <ul className="sidebar__block">
                <SidebarCategory title="Correspondence" icon="book">
                    <SidebarLink
                        title="Outgoing"
                        route="/correspondence/outgoing"
                        onClick={hideSidebar}
                        count={TotalPendingOutgoing}
                        count_return={TotalReturnOutgoing}
                        count_pending={TotalNYAOutgoing}
                    />
                    <SidebarLink
                        title="Incoming"
                        route="/correspondence/incoming"
                        onClick={hideSidebar}
                        count={TotalPendingIncoming}
                    />
                    <SidebarLink
                        title="Approved"
                        route="/correspondence/approved"
                        onClick={hideSidebar}
                        count={TotalPendingApproved}
                    />
                    <SidebarLink
                        title="Archives"
                        route="/correspondence/archives"
                        onClick={hideSidebar}
                        count={TotalPendingArchived}
                    />
                </SidebarCategory>
                {state.role === "Admin" || state.role === "Super Admin" ? (
                    <SidebarCategory title="Admin" icon="users">
                        <SidebarLink title="User Assignment" route="/admin/user" onClick={hideSidebar} />
                        <SidebarLink title="Document Setup Manager" route="/admin/manager" onClick={hideSidebar} />
                    </SidebarCategory>
                ) : null}
                {state.role === "Super Admin" ? (
                    <SidebarCategory title="Super Admin" icon="users">
                        <SidebarLink title="Admin Assignment" route="/superadmin/admin" onClick={hideSidebar} />
                        <SidebarLink title="Document Setup Manager" route="/superadmin/manager" onClick={hideSidebar} />
                        <SidebarLink
                            title="Assigning of Department"
                            route="/superadmin/department"
                            onClick={hideSidebar}
                        />
                    </SidebarCategory>
                ) : null}
                <SidebarCategory title="Report" icon="book">
                    <SidebarLink title="Outgoing Report" route="/correspondence/reports" onClick={hideSidebar} />
                    <SidebarLink
                        title="Incoming Report"
                        route="/correspondence/incomingreports"
                        onClick={hideSidebar}
                    />
                </SidebarCategory>
                <Row></Row>
                <Row></Row>
                <Button
                    className="btn btn-primary"
                    size="sm"
                    outline
                    style={{
                        marginLeft: "50px",
                        marginTop: "5px",
                        padding: "5px",
                        color: "white",
                        backgroundColor: "#c21b1b",
                    }}
                    onClick={handleUserManualOpen}
                >
                    <PrintIcon />
                    User Manual
                </Button>
                <Button
                    className="btn btn-primary"
                    size="sm"
                    outline
                    style={{
                        marginLeft: "20px",

                        padding: "5px",
                        color: "white",
                        backgroundColor: "#c21b1b",
                    }}
                    onClick={handleEvalOpen}
                >
                    <PrintIcon />
                    DTS Usability Evaluation
                </Button>
            </ul>
        </div>
    );
};

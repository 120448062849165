export const ACTIONS = {
    LOGIN_ACTION: "LOGIN_ACTION",
    COUNT_INCOMING_ACTION: "COUNT_INCOMING_ACTION",
    COUNT_INCOMINGBYDEPT_ACTION: "COUNT_INCOMINGBYDEPT_ACTION",
    COUNT_APPROVED_ACTION: "COUNT_APPROVED_ACTION",
    COUNT_APPROVEDBYDEPT_ACTION: "COUNT_APPROVEDBYDEPT_ACTION",
    COUNT_ARCHIVED_ACTION: "COUNT_ARCHIVED_ACTION",
    COUNT_ARCHIVEDBYDEPT_ACTION: "COUNT_ARCHIVEDBYDEPT_ACTION",
    COUNT_OUTGOING_ACTION: "COUNT_OUTGOING_ACTION",
    COUNT_OUTGOINGBYDEPT_ACTION: "COUNT_OUTGOINGBYDEPT_ACTION",
    COUNT_RETURN_OUTGOING_ACTION: "COUNT_RETURN_OUTGOING_ACTION",
    COUNT_PENDING_OUTGOING_ACTION: "COUNT_PENDING_OUTGOING_ACTION",
    COUNT_RETURN_OUTGOINGBYDEPT_ACTION: "COUNT_RETURN_OUTGOINGBYDEPT_ACTION",
    COUNT_PENDING_OUTGOINGBYDEPT_ACTION: "COUNT_PENDING_OUTGOINGBYDEPT_ACTION",
    OUTGOING_DEPARTMENT_ACTION: "OUTGOING_DEPARTMENT_ACTION",
};

import React from "react";
import { BrowserRouter } from "react-router-dom";

import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.css";
import "../../scss/app.scss";

import { Routes } from "../Routers/Routes";
import ScrollToTop from "./ScrollToTop";
import { RootProvider } from "../../context/Context";

export const App: React.FC = () => {
    return (
        <BrowserRouter>
            <ScrollToTop>
                <>
                    <div>
                        <RootProvider>
                            <Routes />
                            <ToastContainer />
                        </RootProvider>
                    </div>
                </>
            </ScrollToTop>
        </BrowserRouter>
    );
};

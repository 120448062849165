import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { LogInForm } from "./components/LogInForm";
import Button from "@material-ui/core/Button";
import dataPrivacy from "../../shared/img/logo/data-privacy.jpg";
import dataLogo from "../../shared/img/logo/usep-logo2.png";

export const LogIn: React.FC = () => {
    const [open, setOpen] = React.useState(true);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleClickToOpen = () => {
        setOpen(true);
    };

    const handleToClose = () => {
        setOpen(false);
    };
    return (
        <div className="account">
            <div className="account__wrapper">
                <div className="account__card">
                    <img src={dataLogo} style={{ width: "30%", marginLeft: "140px", marginBottom: "20px" }} />
                    <div className="account__head">
                        <h3 className="account__title">Document Tracking System</h3>
                    </div>
                    <Dialog open={open} onClose={handleToClose}>
                        <DialogTitle style={{ backgroundColor: "#800000", color: "white" }}>{"Agreement"}</DialogTitle>
                        <DialogContent>
                            <img src={dataPrivacy} />
                            <DialogContentText>
                                By continuing to browse this website, you agree to the University of Southeastern
                                Philippines’ Data Privacy The full text of The Statement can be accessed through this{" "}
                                <a target="_blank" href={"https://www.usep.edu.ph/usep-data-privacy-statement/"}>
                                    link.
                                </a>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={handleToClose}
                                color="primary"
                                style={{ backgroundColor: "#800000", color: "white" }}
                                autoFocus
                            >
                                Continue.
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <LogInForm />
                </div>
            </div>
        </div>
    );
};

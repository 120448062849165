import axios from "axios";
import { useContext } from "react";
import { useQuery } from "react-query";
import { Context } from "../../../../context/Context";

export const fetchAuditDept = async (data) => {
    const results = await axios
        .get(
            `${process.env.REACT_APP_HOST}/api/dept/audit/outgoing/${data.queryKey[1]}/${data.queryKey[3].fromDate}/${data.queryKey[3].toDate}/${data.queryKey[3].toFilter}/${data.queryKey[3].subDepartment}/${data.queryKey[3].employee_id}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + data.queryKey[2],
                },
            }
        )
        .then((res) => res.data.data);

    return results;
};

export const useAuditTrailDept = (data) => {
    const { state } = useContext(Context);

    return useQuery(["audit_documents_dept", state.employee_id, state.token, data], fetchAuditDept);
};

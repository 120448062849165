export const InitialState = {
    token: "",
    employee_id: 0,
    full_name: "",
    email: "",
    department_id: 0,
    department_name: "",
    role: "",
    count_incoming: 0,
    count_incoming_dept: 0,
    count_approved: 0,
    count_approved_dept: 0,
    count_archived: 0,
    count_archived_dept: 0,
    count_outgoing: 0,
    count_outgoing_dept: 0,
    count_outgoing_return: 0,
    count_outgoing_pending: 0,
    count_outgoing_return_dept: 0,
    count_outgoing_pending_dept: 0,
    list_department: [],
    department_id_action: 0,
    department_name_action: "",
    campus: "",
    department_subunit_action: 0,
    isSubUnit: 0,
};

export interface State {
    token: string;
    employee_id: number;
    full_name: string;
    email: string;
    department_id: number;
    department_name: string;
    role: string;
    count_incoming: number;
    count_incoming_dept: number;
    count_approved: number;
    count_approved_dept: number;
    count_archived: number;
    count_archived_dept: number;
    count_outgoing: number;
    count_outgoing_dept: number;
    count_outgoing_return: number;
    count_outgoing_pending: number;
    count_outgoing_pending_dept: number;
    count_outgoing_return_dept: number;
    list_department: any;
    department_id_action: number;
    department_name_action: string;
    campus: string;
    department_subunit_action: number;
    isSubUnit: number;
}

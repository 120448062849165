import React, { useContext, useMemo } from "react";
import Swal from "sweetalert2";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DoneAllIcon from "@material-ui/icons/DoneAll";

import { Context } from "../../../../context/Context";
import { EnchancedModal } from "../../../../components/Modal/EnhancedModal";
import { EnhancedTable } from "../../../../components/Table/EnhancedTable";
import { useApprovedDocuments } from "../hooks/useApprovedDocuments";
import { useDocument } from "../hooks/useDocument";
import { useLastRoute } from "../hooks/useLastRoute";

interface Props {
    open: boolean;
    setOpen: any;
    id: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(1),
            width: 10,
            height: 10,
        },
        extendedIcon: {
            marginRight: theme.spacing(1),
        },
        root: {
            width: "100%",
        },
        paper: {
            width: "100%",
            marginBottom: theme.spacing(2),
        },
    })
);

export const ViewApprovedModal: React.FC<Props> = ({ open, setOpen, id }) => {
    const { data } = useDocument(id);
    const { data: dataLastRoute = [] } = useLastRoute(id);

    const classes = useStyles();
    const { state } = useContext(Context);
    const [approved] = useApprovedDocuments();

    const columns: any = useMemo(
        () => [
            {
                Header: "Name",
                accessor: "name",
                width: 190,
                Cell: (row: any) => {
                    const is_additional = row.cell.row.original.is_additional;
                    return is_additional ? (
                        <p style={{ color: "#d65238" }}>{row.cell.row.original.name}</p>
                    ) : (
                        <p>{row.cell.row.original.name}</p>
                    );
                },
            },
            {
                Header: "Status",
                accessor: "status",
                width: 90,
            },
            {
                headerClassName: "correspondence-action",
                Header: "Approved",
                accessor: "attach_name",
                Cell: (row: any) => {
                    const lastRoute: any = dataLastRoute.find((rs: any) => rs.id === row.cell.row.original.id);

                    return (
                        <IconButton
                            ria-label="download"
                            className={classes.margin}
                            onClick={() => {
                                handleApproved(row.cell.row.original.id, row.cell.row.original.document_id);
                            }}
                            disabled={
                                row.cell.row.original.status === "Released" ||
                                row.cell.row.original.status === "Approved" ||
                                (lastRoute?.id === row.cell.row.original.id &&
                                    row.cell.row.original.status !== "Not yet acknowledged")
                                    ? true
                                    : false
                            }
                        >
                            <DoneAllIcon fontSize="small" />
                        </IconButton>
                    );
                },
                width: 100,
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dataLastRoute]
    );

    const handleClose = () => {
        setOpen(false);
    };

    const handleApproved = async (id: string, document_id: string) => {
        Swal.fire({
            title: "Information!",
            text: "Are you sure you want to approved it.?",
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, approved it!",
            customClass: {
                container: "view_modal",
            },
        }).then(async (result) => {
            if (result.isConfirmed) {
                approved.mutate({
                    document_id: document_id,
                    createdBy: state.employee_id,
                    id: id,
                });
                Swal.fire({
                    title: "Success!",
                    text: "Your file has been approved.",
                    icon: "success",
                    customClass: {
                        container: "view_modal",
                    },
                });
            }
        });
    };

    return useMemo(
        () => (
            <>
                <div>
                    <EnchancedModal
                        open={open}
                        setOpen={setOpen}
                        title={"List of Routes"}
                        subtitle={""}
                        view={true}
                        handleClose={handleClose}
                    >
                        <EnhancedTable columns={columns} data={data} view={false} />
                    </EnchancedModal>
                </div>
            </>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [open, data, dataLastRoute]
    );
};

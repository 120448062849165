import React from "react";
import { Col } from "reactstrap";
import { useAsyncDebounce } from "react-table";
import SearchIcon from "mdi-react/SearchIcon";


export const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  
  return (
    <Col lg={4}>
      <div className="form__form-group">
        <div className="form__form-group-field">
          
          <div className="form__form-group-icon">
            <SearchIcon />
          </div>
          <input
            className="form-control"
            value={value || ""}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
            placeholder={`Search records...`}
            style={{ height: 31 }}
          />
         
        </div>
      </div>
    </Col>
  );
};

import React from "react";
import moment from "moment";
import { EnchancedModal } from "../../../../components/Modal/EnhancedModal";
import { EnhancedTable } from "../../../../components/Table/EnhancedTable";
import { useDepartments } from "../hooks/useDepartments";
import { useOldDepartments } from "../hooks/useOldDepartments";
import { useDocument } from "../hooks/useDocument";

interface Props {
    open: boolean;
    setOpen: any;
    id: string;
}

export const ViewModal: React.FC<Props> = ({ open, setOpen, id }) => {
    const { data, isLoading } = useDocument(id);

    const { data: departments } = useDepartments();
    const { data: oldDepartments } = useOldDepartments();
    const columns: any = React.useMemo(
        () => [
            {
                Header: "Name",
                accessor: "name",
                width: 190,
                Cell: (row: any) => {
                    const is_additional = row.cell.row.original.is_additional;
                    return is_additional ? (
                        <p style={{ color: "#d65238" }}>{row.cell.row.original.name}</p>
                    ) : (
                        <p>{row.cell.row.original.name}</p>
                    );
                },
            },
            {
                Header: "Department",
                accessor: "department",
                width: 150,
                Cell: (row: any) => {
                    const department = departments?.find(
                        (results: any) => results.DepartmentID === Number(row.cell.row.original.department)
                    );
                    const oldDepartment = oldDepartments?.find(
                        (results: any) => results.DepartmentID === Number(row.cell.row.original.department)
                    );
                    const is_additional = row.cell.row.original.is_additional;

                    return is_additional ? (
                        <p style={{ color: "#d65238" }}>
                            {row.cell.row.original.department_name ||
                                department?.DepartmentName ||
                                oldDepartment?.DepartmentName ||
                                "Loading"}
                        </p>
                    ) : (
                        <p>
                            {row.cell.row.original.department_name ||
                                department?.DepartmentName ||
                                oldDepartment?.DepartmentName ||
                                "Loading"}
                        </p>
                    );
                },
            },
            {
                Header: "Remarks",
                accessor: "remarks",
                width: 100,
                Cell: (row: any) => {
                    const is_additional = row.cell.row.original.is_additional;
                    return is_additional ? (
                        <p style={{ color: "#d65238" }}>{row.cell.row.original.remarks}</p>
                    ) : (
                        <p>{row.cell.row.original.remarks}</p>
                    );
                },
            },
            {
                Header: "Status",
                accessor: "status",
                width: 100,
                Cell: (row: any) => {
                    const is_additional = row.cell.row.original.is_additional;
                    return is_additional ? (
                        <p style={{ color: "#d65238" }}>{row.cell.row.original.status}</p>
                    ) : (
                        <p>{row.cell.row.original.status}</p>
                    );
                },
            },
            {
                Header: "Duration",
                accessor: "no_days",
                width: 120,

                Cell: (row: any) => {
                    let day_transaction =
                        row.cell.row.original?.is_released === 1
                            ? row.cell.row.original?.createdAt
                                ? moment(row.cell.row.original.createdAt).seconds(0).milliseconds(0)
                                : null
                            : row.cell.row.original?.updatedAt
                            ? moment(row.cell.row.original.updatedAt).seconds(0).milliseconds(0)
                            : null;

                    let day_current =
                        row.cell.row.original?.is_released === 1
                            ? moment(row.cell.row.original.releasedAt).seconds(0).milliseconds(0)
                            : moment().seconds(0).milliseconds(0);

                    let duration_transaction =
                        day_transaction && day_current ? moment.duration(day_current.diff(day_transaction)) : null;

                    let days_received = duration_transaction ? duration_transaction.days() : null;

                    let hr_received = duration_transaction ? duration_transaction.hours() : null;

                    let min_received = duration_transaction ? duration_transaction.minutes() : null;

                    let label_d = days_received > 1 ? " days " : String(days_received) === " " ? " " : " day ";
                    let label_h = hr_received > 1 ? " hours " : String(hr_received) === " " ? " " : " hour ";

                    let label_mins = min_received > 1 ? " mins " : String(min_received) === " " ? " " : " min";

                    const is_additional = row.cell.row.original.is_additional;
                    const label_duration =
                        row.cell.row.original.status !== "Pending"
                            ? days_received + label_d + hr_received + label_h + min_received + label_mins
                            : "";
                    return is_additional ? (
                        <p style={{ color: "#d65238" }}>{label_duration}</p>
                    ) : (
                        <p>{label_duration}</p>
                    );
                    //   return (
                    //   <p></p>;
                    //   );
                },
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleClose = () => {
        setOpen(false);
    };

    if (isLoading) {
        return <span>Loading...</span>;
    }

    return (
        <div>
            <EnchancedModal
                open={open}
                setOpen={setOpen}
                title={"Audit Trail"}
                subtitle={"List of Routes"}
                view={true}
                handleClose={handleClose}
            >
                <EnhancedTable columns={columns} data={data} view={false} />
            </EnchancedModal>
        </div>
    );
};

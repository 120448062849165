import React, { Fragment } from "react";
import {
  useTable,
  useSortBy,
  useFilters,
  useGlobalFilter,
  useExpanded,
  usePagination,
} from "react-table";
import {
  Table,
  Row,
  Col,
  Button,
  Input,
  CustomInput,
  Spinner,
  Label,
  Badge,
} from "reactstrap";
import { GlobalFilter } from "./Filter";

export const TableContainer = ({
  columns,
  data,
  isLoading = false,
  showTotalRows = true,
  pageLen = 100,
 ...props
}) => {
  
  const hooks = [
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
  ];

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    visibleColumns,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: pageLen },
    },
    ...hooks
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  return (
    <>
      <Col>
        {data.length > 0 && showTotalRows && (
          <label
            style={{
              float: "right",
              fontWeight: "bold",
            }}
          >
            Total Rows:{" "}
            <Badge color="secondary" style={{ fontSize: 12 }}>
              {data.length}
            </Badge>
          </label>
        )}
      </Col>
      <GlobalFilter
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <Col lg={12} xs={12}>
        <Table bordered hover {...getTableProps()} {...props}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    <div {...column.getSortByToggleProps()}>
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {isLoading && (
              <tr>
                <td align="center" colSpan={visibleColumns.length}>
                  <Label>
                    Fetching data... <Spinner size="sm" color="primary" />
                  </Label>
                </td>
              </tr>
            )}
            {!isLoading && (
              <>
                {page.length === 0 && (
                  <tr>
                    <td align="center" colSpan={visibleColumns.length}>
                      No records found
                    </td>
                  </tr>
                )}
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <Fragment key={row.getRowProps().key}>
                      <tr>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              style={{
                                verticalAlign: "middle",
                                padding: 0,
                                paddingInline: "0.75rem",
                              }}
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    </Fragment>
                  );
                })}
              </>
            )}
          </tbody>
        </Table>
      </Col>
      {page.length > 0 && !isLoading && (
        <Row style={{ maxWidth: 1000, margin: "0 auto", textAlign: "center" }}>
          <Col md={3}>
            <Button
              color="primary"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </Button>
            <Button
              color="primary"
              onClick={previousPage}
              disabled={!canPreviousPage}
            >
              {"<"}
            </Button>
          </Col>
          <Col md={2} style={{ marginTop: 7 }}>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </Col>
          <Col md={2}>
            <Input
              type="number"
              min={1}
              style={{ width: 70 }}
              max={pageOptions.length}
              defaultValue={pageIndex + 1}
              onChange={onChangeInInput}
            />
          </Col>
          <Col md={2}>
            <CustomInput
              type="select"
              id="pageSize"
              value={pageSize}
              onChange={onChangeInSelect}
            >
              {[100,150,200,250,500].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </CustomInput>
          </Col>
          <Col md={3}>
            <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
              {">"}
            </Button>
            <Button
              color="primary"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
};

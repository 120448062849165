import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { EnhancedTable } from "../../../../components/Table/EnhancedTable";
import { useApprovedDocumentsByDept } from "../hooks/useApprovedDocumentsByDept";
import { useFiles } from "../hooks/useRetrieveFiles";
import { useApprovedSeen } from "../hooks/useApprovedSeen";
import { Context } from "../../../../context/Context";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import Swal from "sweetalert2";
import ViewIcon from "@material-ui/icons/Visibility";
import { ViewFileModal } from "./ViewFileModal";
import { ViewModal } from "./ViewModal";
interface Props {
    toDate?: string;
    fromDate?: string;
    isFilter?: boolean;
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(1),
            width: 10,
            height: 10,
        },
        extendedIcon: {
            marginRight: theme.spacing(1),
        },
        root: {
            width: "100%",
        },
        paper: {
            width: "100%",
            marginBottom: theme.spacing(2),
        },
    })
);

export const ApprovedTableByDept: React.FC<Props> = ({ fromDate, toDate, isFilter }) => {
    const classes = useStyles();
    const { dispatch, state } = useContext(Context);
    const { data = [], isLoading } = useApprovedDocumentsByDept({
        fromDate,
        toDate,
        isFilter,
    });
    const [seen] = useApprovedSeen();
    const {
        data: { data: dataFiles = [] },
    }: any = useFiles();
    const [openFile, setFileOpen] = useState<boolean>(false);
    const [id, setId] = useState({
        id: "",
        document_id: "",
        userid: 0,
        username: "",
    });
    const [docid, setDocId] = useState<string>("");
    const [open, setOpen] = useState<boolean>(false);
    useEffect(() => {
        if (isLoading === false) {
            dispatch({
                type: "COUNT_APPROVEDBYDEPT_ACTION",
                count_approved_dept: data.length,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const columns: any = React.useMemo(
        () => [
            {
                Header: "Reference No",
                accessor: "reference_no",
                width: 130,
            },
            {
                Header: "Source Office",
                accessor: "source",
                width: 130,
            },

            {
                Header: "Type",
                accessor: "document_type",
                width: 130,
            },
            {
                Header: "Category",
                accessor: "category",
                width: 150,
            },
            {
                Header: "Purpose/Action Required",
                accessor: "purpose",
                width: 150,
            },
            {
                Header: "Subject Matter",
                accessor: "subject_matter",
                width: 150,
            },

            {
                Header: "Document Date",
                accessor: "createdAt",
                Cell: (row: any) => <>{moment(row.cell.value).format("MM/DD/YYYY hh:mm a")}</>,
                width: 120,
            },
            {
                Header: "Approved / Released Date",
                accessor: "releasedAt",
                Cell: (row: any) => (
                    <>
                        {row.cell.row.original.list_route?.releasedAt
                            ? moment(row.cell.row.original.list_route?.releasedAt).format("MM/DD/YYYY hh:mm a")
                            : ""}
                    </>
                ),
                width: 110,
            },
            {
                Header: "Received By",
                accessor: "received_by",
                Cell: (row: any) => (
                    <>{row.cell.row.original.list_route?.name ? row.cell.row.original.list_route.name : ""}</>
                ),
            },
            {
                Header: "File",
                accessor: "attach_name",
                Cell: (row: any) => {
                    const filter = dataFiles.filter((data) => data.document_id === row.cell.row.original.id);
                    return (
                        <div>
                            {filter.map((item: any, index: number) => {
                                return (
                                    <p key={index} style={{ color: "red" }}>
                                        {item.attach_name ? item.attach_name : "No File"}
                                    </p>
                                );
                            })}
                        </div>
                    );
                },
            },

            {
                headerClassName: "correspondence-action",
                Header: "Action",
                accessor: "action",
                Cell: (row: any) => {
                    const filter = dataFiles.filter(
                        (data) => data.document_id === row.cell.row.original.id && data.attach_name !== null
                    );
                    return (
                        <>
                            <Tooltip title="View">
                                <IconButton
                                    ria-label="view"
                                    className={classes.margin}
                                    onClick={() => {
                                        handleViewIcon(row.cell.row.original.id);
                                    }}
                                >
                                    <ViewIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <IconButton
                                ria-label="download"
                                className={classes.margin}
                                onClick={() => {
                                    handleViewFiles(row.cell.row.original.id);
                                }}
                                disabled={!filter.length ? true : false}
                            >
                                <CloudDownloadIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                                ria-label="approved"
                                className={classes.margin}
                                onClick={() => {
                                    handleSeenFiles(row.cell.row.original.id);
                                }}
                                disabled={
                                    row.cell.row.original.createdBy !== state.employee_id ||
                                    row.cell.row.original.viewed === 1
                                        ? true
                                        : false
                                }
                            >
                                <DoneAllIcon fontSize="small" />
                            </IconButton>
                        </>
                    );
                },
                width: 150,
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleViewFiles = (item_id: string) => {
        setId({ ...id, id: item_id });
        setFileOpen(true);
    };

    const handleSeenFiles = (id: string) => {
        Swal.fire({
            title: "Information!",
            text: "Are you sure you want to seen it.?",
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, released it!",
            customClass: {
                container: "view_modal",
            },
        }).then(async (result) => {
            if (result.isConfirmed) {
                seen.mutate({ document_id: id, createdBy: state.employee_id });
                Swal.fire("Seen!", "Your document has been released.", "success");
            }
        });
    };

    const handleViewIcon = (id: string) => {
        setDocId(id);
        setOpen(true);
    };

    if (isLoading) {
        return <span>Loading...</span>;
    }

    return (
        <div className={classes.root}>
            <EnhancedTable columns={columns} data={data} view={true} />
            {id ? <ViewFileModal openFile={openFile} setFileOpen={setFileOpen} id={id.id} /> : ""}
            {id ? <ViewModal open={open} setOpen={setOpen} id={docid} /> : ""}
        </div>
    );
};

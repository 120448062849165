/* eslint-disable no-useless-concat */
/* eslint-disable array-callback-return */
import React, { useContext, useState } from "react";
import classNames from "classnames";
import { Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { Context } from "../../../../context/Context";
import { ReportsIncomingTable } from "./ReportsIncomingTable";
import { ReportsIncomingTableDept } from "./ReportsIncomingTableDept";

import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFetchAssignedDepartment } from "../hooks/useAssignedDepartment";
import { useDepartments } from "../../../Department/hooks/useDepartments";
import Select from "react-select";
const moment = require("moment");
const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    dateField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
}));
const customStyles = {
    container: (styles: any) => ({
        ...styles,
        marginLeft: 10,
        marginTop: 20,
        width: 250,
    }),
    control: (styles: any) => ({
        ...styles,
        width: 250,
    }),
};

const customStylesSubDept = {
    container: (styles: any) => ({
        ...styles,
        marginLeft: 10,
        marginTop: 20,
        width: 480,
    }),
    control: (styles: any) => ({
        ...styles,
        width: 480,
    }),
};
export const ReportsIncomingTabs: React.FC = () => {
    const [activeTab, setActiveTab] = useState("1");
    const { state } = useContext(Context);

    const toggle = ({ tab }: { tab: any }) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    const current = moment(new Date()).format("YYYY-MM-DD").toString();
    const [fromDate, setFromDate] = useState<string>(current);
    const [toDate, setToDate] = useState<string>(current);
    const [toFilter, setToFilter] = useState<number>(1);
    const [isFilter, setFilter] = useState<boolean>(true);
    const [isShowSub, setShowSub] = useState<boolean>(false);
    const [toSubDept, setSubDept] = useState<number>(0);
    const classes = useStyles();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleIsFilter = (event) => {
        setFilter(event.target.checked);
        setFromDate(null);
        setToDate(null);
    };
    const { data = [] } = useFetchAssignedDepartment();
    const { data: dataDepartment } = useDepartments();
    let permission = [];
    if (state.role === "Admin") {
        if (data.length > 0) {
            permission = [
                { label: "My Transactions", value: 1 },
                { label: "Offices/Departments", value: 2 },
            ];
        } else {
            permission = [{ label: "My Transactions", value: 1 }];
        }
    } else {
        permission = [
            { label: "My Transactions", value: 1 },
            { label: "Offices/Departments", value: 2 },
            { label: "View All", value: 3 },
        ];
    }

    const handleSelectFilter = (event) => {
        if (event !== null) {
            setToFilter(event.value);
            setShowSub(true);
            if (event.value === 2) {
                setShowSub(true);
            } else {
                setShowSub(false);
            }
        } else {
            setToFilter(1);
            setShowSub(false);
            setSubDept(0);
        }
    };

    const handleSelectSubDept = (event) => {
        if (event !== null) {
            setSubDept(event.value);
        } else {
            setSubDept(0);
        }
    };

    return (
        <div className="tabs__wrap">
            <span>Filter Incoming document date range</span>

            {/* <Checkbox
                inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                checked={isFilter}
                onChange={handleIsFilter}

            /> */}
            {isFilter ? (
                <TextField
                    id="dateFrom"
                    size="small"
                    label="Date from"
                    type="date"
                    defaultValue={current}
                    onChange={(event) => {
                        setFromDate(event.target.value);
                    }}
                    className={classes.dateField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            ) : (
                ""
            )}

            {isFilter ? (
                <TextField
                    id="dateTo"
                    size="small"
                    label="Date to"
                    type="date"
                    defaultValue={current}
                    className={classes.dateField}
                    onChange={(event) => {
                        setToDate(event.target.value);
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            ) : (
                ""
            )}

            {state.role === "Admin" || state.role === "Super Admin" ? (
                <Select
                    name="employees"
                    options={permission.map((row) => ({
                        value: row.value,
                        label: row.label,
                    }))}
                    getOptionLabel={(option) => {
                        return `${option.label}`;
                    }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder={"Filter Category Report"}
                    menuPlacement="auto"
                    onChange={handleSelectFilter}
                    styles={customStyles}
                    isClearable={true}
                    defaultValue={{ label: "My Transactions", value: 1 }}
                />
            ) : (
                ""
            )}

            {(state.role === "Admin" && isShowSub) || (state.role === "Super Admin" && isShowSub) ? (
                <Select
                    name="department"
                    options={data.map((row) => ({
                        value: row.assign_department_id,
                        label: row.assign_department_id,
                        department: dataDepartment.find((rs: any) => {
                            if (rs.DepartmentID === row.assign_department_id) {
                                return rs.DepartmentName;
                            }
                        }),
                    }))}
                    getOptionLabel={(option) => {
                        return `${option.department.DepartmentName}` + "-" + `${option.department.CampusName}`;
                    }}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder={"Office Assignment"}
                    menuPlacement="auto"
                    onChange={handleSelectSubDept}
                    styles={customStylesSubDept}
                    isClearable={true}
                />
            ) : (
                ""
            )}

            <Nav tabs>
                <NavItem>
                    <NavLink className={classNames({ active: activeTab === "1" })} onClick={() => toggle({ tab: "1" })}>
                        <Row>UNIWIDE</Row>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classNames({ active: activeTab === "2" })} onClick={() => toggle({ tab: "2" })}>
                        <Row>INTERNAL(by office)</Row>
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <ReportsIncomingTable
                        defaultDepartment={false}
                        fromDate={fromDate}
                        toDate={toDate}
                        toFilter={toFilter}
                        toSubDept={toSubDept}
                    />
                </TabPane>
                <TabPane tabId="2">
                    <ReportsIncomingTableDept
                        defaultDepartment={true}
                        fromDate={fromDate}
                        toDate={toDate}
                        toFilter={toFilter}
                        toSubDept={toSubDept}
                    />
                </TabPane>
            </TabContent>
        </div>
    );
};

import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

import EyeIcon from "mdi-react/EyeIcon";
import KeyVariantIcon from "mdi-react/KeyVariantIcon";
import AccountOutlineIcon from "mdi-react/AccountOutlineIcon";
import { Button } from "reactstrap";
import { toastify } from "../../../components/Toastr";
import { Context } from "../../../context/Context";

export const LogInForm: React.FC = () => {
    const history = useHistory();
    const { dispatch } = useContext(Context);
    const [showPassword, setShowPassword] = useState(false);
    const [details, setDetails] = useState({
        user_id: "",
        password: "",
    });

    const handleChange =
        (name) =>
        ({ target: { value } }) => {
            setDetails({ ...details, [name]: value });
        };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const { user_id, password } = details;

        try {
            /* main api */
            // const { data } = await axios.post(`${process.env.REACT_APP_HOST}/signin`, {
            //     user_id: user_id,
            //     password: password,
            // });

            /* bypass all login */
            const { data } = await axios.post(`${process.env.REACT_APP_HOST}/signinbypass`, {
                user_id: user_id,
                password: password,
            });

            if (!data.status) {
                return toastify(199, data.message);
            } else {
                const {
                    data: {
                        accessToken,
                        role,
                        data: {
                            FirstName,
                            LastName,
                            MiddleName,
                            EmployeeID,
                            Email,
                            OfficeID,
                            Office,
                            SubunitID,
                            SubunitName,
                            Campus,
                        },
                    },
                } = data;

                let list_department = [];
                if (SubunitID !== null) {
                    list_department = [
                        {
                            department_name: SubunitName,
                            department_id: SubunitID,
                            campus_name: "",
                            isSubUnit: 1,
                        },
                    ];
                }

                Office.forEach((data, i) => {
                    if (data !== "") {
                        const list = {
                            department_name: data,
                            department_id: OfficeID[i],
                            campus_name: Campus[i],
                            isSubUnit: 0,
                        };
                        list_department.push(list);
                    }
                });

                dispatch({
                    type: "LOGIN_ACTION",
                    token: accessToken,
                    employee_id: EmployeeID,
                    full_name: LastName + ", " + FirstName + " " + MiddleName.charAt(0) + ".",
                    email: Email,
                    department_id: SubunitID
                        ? SubunitID
                        : Number(OfficeID[1])
                        ? Number(OfficeID[1])
                        : Number(OfficeID[0]),
                    department_name: SubunitName ? SubunitName : Office[1] ? Office[1] : Office[0],
                    role: role,
                    list_department,
                    campus: Campus[1] ? Campus[1] : Campus[0],
                    isSubUnit: SubunitName ? 1 : 0,
                });

                history.push("/correspondence/incoming");

                return toastify(200, `Welcome ${Email}`);
            }
        } catch (err) {
            return toastify(400, `Error ${err}`);
        }
    };

    return (
        <form className="form">
            <div className="form__form-group">
                <span className="form__form-group-label">User ID</span>
                <div className="form__form-group-field">
                    <div className="form__form-group-icon">
                        <AccountOutlineIcon />
                    </div>
                    <input onChange={handleChange("user_id")} type="text" placeholder="User ID" />
                </div>
            </div>
            <div className="form__form-group">
                <span className="form__form-group-label">Password</span>
                <div className="form__form-group-field">
                    <div className="form__form-group-icon">
                        <KeyVariantIcon />
                    </div>
                    <input
                        onChange={handleChange("password")}
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                    />
                    <button
                        className={`form__form-group-button${showPassword ? " active" : ""}`}
                        onClick={() => {
                            setShowPassword((showPassword) => !showPassword);
                        }}
                        type="button"
                    >
                        <EyeIcon />
                    </button>
                </div>
            </div>
            <Button
                className="btn btn-primary account__btn account__btn--small"
                style={{ color: "white", backgroundColor: "#c21b1b" }}
                onClick={handleSubmit}
            >
                Sign In
            </Button>
        </form>
    );
};

import React from "react";
import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {
    useColumnOrder,
    useExpanded,
    useFilters,
    useFlexLayout,
    useGlobalFilter,
    useGroupBy,
    usePagination,
    useResizeColumns,
    useRowSelect,
    useSortBy,
    useTable,
} from "react-table";

import { TablePaginationActions } from "./TablePaginationActions";
import { TableToolbar } from "./TableToolbar";

interface Props {
    columns: any;
    data?: any;
    setData?: any;
    updateMyData?: any;
    skipPageReset?: any;
    AddModal?: React.ReactNode;
    view: boolean;
    id?: string;
}

export const EnhancedTable: React.FC<Props> = ({
    columns,
    data,
    setData,
    updateMyData,
    skipPageReset,
    AddModal,
    view,
    ...props
}) => {
    const hooks = [
        useGlobalFilter,
        useColumnOrder,
        useFilters,
        useGroupBy,
        useSortBy,
        useExpanded,
        useFlexLayout,
        usePagination,
        useResizeColumns,
        useRowSelect,
    ];

    const instance = useTable(
        {
            columns,
            data,
        },
        ...hooks
    );

    const {
        headerGroups,
        prepareRow,
        page,
        gotoPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize, globalFilter },
    }: any = instance;

    const handleChangePage = (event: any, newPage: any) => {
        gotoPage(newPage);
    };

    const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
        setPageSize(Number(event.target.value));
    };

    return (
        <TableContainer>
            <TableToolbar
                AddModal={AddModal}
                preGlobalFilteredRows={preGlobalFilteredRows}
                setGlobalFilter={setGlobalFilter}
                globalFilter={globalFilter}
            />
            <MaUTable {...props}>
                <TableHead>
                    {headerGroups.map((headerGroup: any) => (
                        <TableRow {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column: any) => (
                                <TableCell
                                    {...(column.id === "selection"
                                        ? column.getHeaderProps()
                                        : column.getHeaderProps(column.getSortByToggleProps()))}
                                >
                                    {column.render("Header")}
                                    {column.id !== "selection" ? (
                                        <TableSortLabel
                                            active={column.isSorted}
                                            direction={column.isSortedDesc ? "desc" : "asc"}
                                        />
                                    ) : null}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableHead>
                <TableBody>
                    {page.map((row: any, i: any) => {
                        prepareRow(row);
                        return (
                            <TableRow {...row.getRowProps()}>
                                {row.cells.map((cell: any) => {
                                    return <TableCell {...cell.getCellProps()}>{cell.render("Cell")}</TableCell>;
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
                {view ? (
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, { label: "All", value: data.length }]}
                                colSpan={3}
                                count={data.length}
                                rowsPerPage={pageSize}
                                page={pageIndex}
                                SelectProps={{
                                    inputProps: { "aria-label": "rows per page" },
                                    native: true,
                                }}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                                style={{ display: "flex" }}
                            />
                        </TableRow>
                    </TableFooter>
                ) : (
                    ""
                )}
            </MaUTable>
        </TableContainer>
    );
};

import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { EnchancedModal } from "../../../../components/Modal/EnhancedModal";
import { toastify } from "../../../../components/Toastr";
import { useApproved } from "../hooks/useApproved";
import TextField from "@material-ui/core/TextField";
interface Props {
    open: boolean;
    setOpen: any;
    data: any;
}

export const IncomingModal: React.FC<Props> = ({ open, setOpen, data }) => {
    const [create] = useApproved();
    const [files, setFiles] = useState<any>([]);
    const [remarks,setRemarks] =  useState<string>("");

    const handleFile = (event) => {
        setFiles(event.target.files);
    };

    const handleChange = (name) => ({ target: { value } }) => {
        setRemarks(value);
    };

    const handleClose = () => {
        setOpen(false);
        setFiles([]);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        const lists = [...files];

        if (!lists.length) {
            return toastify(199, "Upload files is required.");
        }

        if(!remarks.trim()){
            return toastify(199, "Remarks is required.");
        }

        const formData = new FormData();
        data.remarks = remarks;
        for (let i = 0; i <= files.length; i++) {
            formData.append("files", files[i]);
        }

        formData.append("data", JSON.stringify(data));
        create.mutate(formData);
        setOpen(false);
        setFiles([]);
    };

    const ListItem = () => {
        const lists = [...files];
        return (
            <ol>
                {lists.map((item: any, index: number) => (
                    <li key={index} style={{ padding: 5 }}>{item.name}</li>
                ))}
            </ol>
        )
    }
    return (
        <div>
            <EnchancedModal
                open={open}
                setOpen={setOpen}
                title={"Approved Details"}
                subtitle={"Upload files"}
                view={true}
            >
                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                        <DialogTitle id="form-dialog-title">Incoming correspondence</DialogTitle>
                        <DialogContent style={{ width: 600, overflow: "hidden" }}>
                            <Button variant="contained" component="label" style={{ marginTop: 10 }}>
                                Upload File
                                <input
                                    accept=".doc,.docx,application/pdf,application/msword,
                                        application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    type="file"
                                    hidden
                                    onChange={handleFile}
                                    multiple
                                />
                            </Button>
                            <InputLabel style={{ marginTop: 10, color: "black" }}>
                                {files.length > 0 ? <ListItem /> : "No file is attached."}
                            </InputLabel>
                            <TextField
                                multiline
                                rows={4}
                                rowsMax={'auto'}
                                margin="dense"
                                label="Remarks"
                                type="remarks"
                                variant="outlined"
                                onChange={handleChange("remarks")}
                                style={{top:'10px',left:'13px',width:'450px'}}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="primary">
                                Cancel
                            </Button>
                            <Button type="submit" color="primary">
                                Save
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </EnchancedModal>
        </div>
    );
};

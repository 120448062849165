import React, { useReducer, useEffect, createContext } from "react";

import { InitialState, State } from "./InitialState";
import { Reducer } from "./Reducer";

export const Context = createContext<{
    state: State;
    dispatch: React.Dispatch<any>;
}>({ state: InitialState, dispatch: () => null });

export const RootProvider = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, InitialState, () => {
        const localState = localStorage.getItem("state");

        return localState ? JSON.parse(localState) : InitialState;
    });

    useEffect(() => {
        localStorage.setItem("state", JSON.stringify(state));
    }, [state]);

    return <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>;
};

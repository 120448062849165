import React from "react";
import ReactDOM from "react-dom";
import { App } from "./pages/App/App";
import { ParallaxProvider } from "react-scroll-parallax";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

ReactDOM.render(
    <QueryClientProvider client={queryClient}>
        <ParallaxProvider>
            <App />
        </ParallaxProvider>
    </QueryClientProvider>,
    document.getElementById("root")
);

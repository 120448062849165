import React from "react";
import { Link } from "react-router-dom";
import TopbarSidebarButton from "./TopbarSidebarButton";
import { TopbarProfile } from "./TopbarProfile";

interface Props {
    changeMobileSidebarVisibility: () => void;
    changeSidebarVisibility: () => void;
}

export const Topbar: React.FC<Props> = ({ changeMobileSidebarVisibility, changeSidebarVisibility }) => {
    return (
        <div className="topbar">
            <div className="topbar__wrapper">
                <div className="topbar__left">
                    <TopbarSidebarButton
                        changeMobileSidebarVisibility={changeMobileSidebarVisibility}
                        changeSidebarVisibility={changeSidebarVisibility}
                    />
                    <Link className="topbar__logo" to="/correspondence/incoming" />
                </div>
                <div className="topbar__right">
                    <span className="topbar__right_logo"></span>
                    <TopbarProfile />
                </div>
            </div>
        </div>
    );
};

import { ACTIONS } from "./Action";
import { State } from "./InitialState";

export const Reducer = (state: State, action: any) => {
    switch (action.type) {
        case ACTIONS.LOGIN_ACTION: {
            return {
                ...state,
                token: action.token,
                employee_id: action.employee_id,
                full_name: action.full_name,
                email: action.email,
                department_id: action.department_id,
                department_name: action.department_name,
                role: action.role,
                list_department: action.list_department,
                campus: action.campus,
                isSubUnit: action.isSubUnit,
            };
        }
        case ACTIONS.COUNT_INCOMING_ACTION: {
            return {
                ...state,
                count_incoming: action.count_incoming,
            };
        }
        case ACTIONS.COUNT_INCOMINGBYDEPT_ACTION: {
            return {
                ...state,
                count_incoming_dept: action.count_incoming_dept,
            };
        }
        case ACTIONS.COUNT_APPROVED_ACTION: {
            return {
                ...state,
                count_approved: action.count_approved,
            };
        }
        case ACTIONS.COUNT_APPROVEDBYDEPT_ACTION: {
            return {
                ...state,
                count_approved_dept: action.count_approved_dept,
            };
        }
        case ACTIONS.COUNT_ARCHIVED_ACTION: {
            return {
                ...state,
                count_archived: action.count_archived,
            };
        }
        case ACTIONS.COUNT_ARCHIVEDBYDEPT_ACTION: {
            return {
                ...state,
                count_archived_dept: action.count_archived_dept,
            };
        }
        case ACTIONS.COUNT_OUTGOING_ACTION: {
            return {
                ...state,
                count_outgoing: action.count_outgoing,
            };
        }
        case ACTIONS.COUNT_OUTGOINGBYDEPT_ACTION: {
            return {
                ...state,
                count_outgoing_dept: action.count_outgoing_dept,
            };
        }
        case ACTIONS.COUNT_RETURN_OUTGOING_ACTION: {
            return {
                ...state,
                count_outgoing_return: action.count_outgoing_return,
            };
        }
        case ACTIONS.COUNT_PENDING_OUTGOING_ACTION: {
            return {
                ...state,
                count_outgoing_pending: action.count_outgoing_pending,
            };
        }
        case ACTIONS.COUNT_RETURN_OUTGOINGBYDEPT_ACTION: {
            return {
                ...state,
                count_outgoing_return_dept: action.count_outgoing_return_dept,
            };
        }
        case ACTIONS.COUNT_PENDING_OUTGOINGBYDEPT_ACTION: {
            return {
                ...state,
                count_outgoing_pending_dept: action.count_outgoing_pending_dept,
            };
        }
        case ACTIONS.OUTGOING_DEPARTMENT_ACTION: {
            return {
                ...state,
                department_id_action: action.department_id_action,
                department_name_action: action.department_name_action,
                department_subunit_action: action.department_subunit_action,
            };
        }
        default:
            return state;
    }
};

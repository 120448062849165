/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useAuditTrail } from "../hooks/useAuditTrail";
import { useProcessTrail } from "../hooks/useProcessTrail";
import { useReleaseTrail } from "../hooks/useReleaseTrail";
import { useReceivedTrail } from "../hooks/useReceivedTrail";
import { useReturnTrail } from "../hooks/useReturnTrail";
import { useResubmitTrail } from "../hooks/useResubmitTrail";
import { TableContainer } from "../../../../components/TableReport/EnhancedTable";
import moment from "moment";
import PrintIcon from "@material-ui/icons/Print";
import dataHeader from "../../../../shared/img/logo/usep-logo2.png";
import { Context } from "../../../../context/Context";
import ISOimg from "../../../../shared/img/logo/ISO-9001.png";
import "../../../../shared/font-style/Old-English-Text-MT_33641-bold";
import "../../../../shared/font-style/arial-narrow_[allfont.net]-normal";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Button, Col, Row } from "reactstrap";

interface Props {
    defaultDepartment: boolean;
    toDate?: string;
    fromDate?: string;
    toFilter?: number;
    toSubDept?: number;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(1),
            width: 10,
            height: "auto",
        },
        extendedIcon: {
            marginRight: theme.spacing(1),
        },
        root: {
            width: "100%",
        },
        paper: {
            width: "100%",
            marginBottom: theme.spacing(2),
        },
    })
);

const doc: any = new jsPDF("l", "pt", "legal");
const width = doc.internal.pageSize.getWidth();
const height = doc.internal.pageSize.getHeight();

export const ReportTable: React.FC<Props> = ({ fromDate, toDate, defaultDepartment, toFilter, toSubDept }) => {
    const classes = useStyles();
    const { state } = useContext(Context);
    const { department_id, employee_id } = state;
    const subDepartment = toSubDept ? toSubDept : department_id;
    const { data = [], isLoading } = useAuditTrail({
        fromDate,
        toDate,
        toFilter,
        subDepartment,
        employee_id,
    });

    const { data: dataProcess = [], isLoading: isLoadingProcess } = useProcessTrail();
    const { data: dataRelease = [], isLoading: isLoadingRelease } = useReleaseTrail();
    const { data: dataReceived = [], isLoading: isLoadingReceived } = useReceivedTrail();
    const { data: dataReturn = [], isLoading: isLoadingReturn } = useReturnTrail();
    const { data: dataResubmit = [], isLoading: isLoadingResubmit } = useResubmitTrail();
    const columns: any = [
        // {
        //   Header: "",
        // },
        {
            Header: "Reference No.",
            accessor: "reference_no",
            width: 120,
        },
        {
            Header: "Source Office",
            accessor: "source",
            width: 200,
        },
        {
            Header: "Document Created",
            accessor: "createdAt",
            Cell: (row: any) => <>{moment(row.cell.value).format("MM/DD/YYYY hh:mm a")}</>,
            width: 100,
        },

        {
            Header: "Document Received",
            accessor: "receivedAt",
            Cell: (row: any) => {
                let filter = [];
                const filterReceived = dataReceived.filter(
                    (data) =>
                        data.document_id === row.cell.row.original.document_id && data.id === row.cell.row.original.id
                );
                const filterResubmit = dataResubmit.filter(
                    (data) =>
                        data.document_id === row.cell.row.original.document_id && data.id === row.cell.row.original.id
                );

                if (row.cell.row.original.status === "Resubmission not yet acknowledge") {
                    filter = filterResubmit;
                } else {
                    filter = filterReceived;
                }

                return (
                    <div>
                        {filter.map((details: any, index: number) => {
                            return (
                                <p key={index} style={{ color: "black" }}>
                                    {details.createdAt ? moment(details.updatedAt).format("MM/DD/YYYY hh:mm a") : ""}
                                </p>
                            );
                        })}
                    </div>
                );
            },
            width: 100,
        },
        {
            Header: "Date and Time Processed",
            Cell: (row: any) => {
                let filter = [];

                const filterProcess = dataProcess.filter(
                    (data) =>
                        data.document_id === row.cell.row.original.document_id &&
                        data.action === "process" &&
                        data.id === row.cell.row.original.id &&
                        moment(data.createdAt).isAfter(row.cell.row.original.createdAt)
                );

                const filterReProcess = dataProcess.filter(
                    (data) =>
                        data.document_id === row.cell.row.original.document_id &&
                        data.action === "re-process" &&
                        data.id === row.cell.row.original.id &&
                        moment(data.createdAt).isAfter(row.cell.row.original.createdAt)
                );

                if (row.cell.row.original.status === "Resubmission not yet acknowledge") {
                    filter = filterReProcess;
                } else {
                    filter = filterProcess;
                }

                return (
                    <div>
                        {filter.slice(0, 1).map((details: any, index: number) => {
                            return (
                                <p key={index} style={{ color: "black" }}>
                                    {details.createdAt ? moment(details.createdAt).format("MM/DD/YYYY hh:mm a") : ""}
                                </p>
                            );
                        })}
                    </div>
                );
            },
            width: 100,
        },
        {
            Header: "Date and Time Release / Returned /Approved",
            Cell: (row: any) => {
                let filter = [];
                const filterRelease = dataRelease.filter(
                    (data) =>
                        data.document_id === row.cell.row.original.document_id && data.id === row.cell.row.original.id
                );

                const filterReturn = dataReturn.filter(
                    (data) =>
                        data.document_id === row.cell.row.original.document_id &&
                        data.id === row.cell.row.original.id &&
                        moment(data.createdAt).isAfter(row.cell.row.original.createdAt)
                );
                if (filterReturn.length > 0) {
                    filter = filterReturn;
                } else {
                    filter = filterRelease;
                }

                return (
                    <div>
                        {filter.slice(0, 1).map((details: any, index: number) => {
                            return (
                                <p key={index} style={{ color: "black" }}>
                                    {details.createdAt ? moment(details.createdAt).format("MM/DD/YYYY hh:mm a") : ""}
                                </p>
                            );
                        })}
                    </div>
                );
            },
            width: 100,
        },
        {
            Header: "Duration",
            Cell: (row: any) => {
                let filter_receieved = [];
                let filter_process = [];
                const filterReceived = dataReceived.filter(
                    (data) =>
                        data.document_id === row.cell.row.original.document_id && data.id === row.cell.row.original.id
                );
                const filterResubmit = dataResubmit.filter(
                    (data) =>
                        data.document_id === row.cell.row.original.document_id && data.id === row.cell.row.original.id
                );

                if (row.cell.row.original.status === "Resubmission not yet acknowledge") {
                    filter_receieved = filterResubmit;
                } else {
                    filter_receieved = filterReceived;
                }

                const filterProcess = dataProcess.filter(
                    (data) =>
                        data.document_id === row.cell.row.original.document_id &&
                        data.action === "process" &&
                        data.id === row.cell.row.original.id &&
                        moment(data.createdAt).isAfter(row.cell.row.original.createdAt)
                );

                const filterReProcess = dataProcess.filter(
                    (data) =>
                        data.document_id === row.cell.row.original.document_id &&
                        data.action === "re-process" &&
                        data.id === row.cell.row.original.id &&
                        moment(data.createdAt).isAfter(row.cell.row.original.createdAt)
                );

                if (row.cell.row.original.status === "Resubmission not yet acknowledge") {
                    filter_process = filterReProcess;
                } else {
                    filter_process = filterProcess;
                }

                let filter_release = [];
                const filterRelease = dataRelease.filter(
                    (data) =>
                        data.document_id === row.cell.row.original.document_id && data.id === row.cell.row.original.id
                );

                const filterReturn = dataReturn.filter(
                    (data) =>
                        data.document_id === row.cell.row.original.document_id &&
                        data.id === row.cell.row.original.id &&
                        moment(data.createdAt).isAfter(row.cell.row.original.createdAt)
                );
                if (filterReturn.length > 0) {
                    filter_release = filterReturn;
                } else {
                    filter_release = filterRelease;
                }

                let day_received = filter_receieved[0]?.updatedAt
                    ? moment(filter_receieved[0].updatedAt).seconds(0).milliseconds(0)
                    : null;

                let day_process = filter_process[0]?.createdAt
                    ? moment(filter_process[0].createdAt).seconds(0).milliseconds(0)
                    : null;

                let day_release = filter_release[0]?.createdAt
                    ? moment(filter_release[0].createdAt).seconds(0).milliseconds(0)
                    : null;

                let duration_process =
                    day_received && day_process ? moment.duration(day_process.diff(day_received)) : null;

                let duration_release =
                    day_process && day_release ? moment.duration(day_release.diff(day_process)) : null;
                let days_release = duration_release ? duration_release.days() : null;
                let days_process = duration_process ? duration_process.days() : null;

                let hr_release = duration_release ? duration_release.hours() : null;
                let hr_process = duration_process ? duration_process.hours() : null;

                let min_release = duration_release ? duration_release.minutes() : null;
                let min_process = duration_process ? duration_process.minutes() : null;

                let total_days = days_release + days_process > 0 ? days_process + days_release : " ";
                let label_d = total_days > 1 ? " days " : total_days === " " ? " " : " day ";
                let total_hr = hr_release + hr_process > 0 ? hr_release + hr_process : " ";
                let label_h = total_hr > 1 ? " hours " : total_hr === " " ? " " : " hour ";

                let total_mins = min_release + min_process > 0 ? min_release + min_process : " ";
                let label_mins = total_mins > 1 ? " mins " : total_mins === " " ? " " : " min";

                if (total_mins >= 60) {
                    total_hr = Number(total_hr) + 1;
                    total_mins = Number(total_mins) - 60;
                    // label_d = " days ";
                    total_hr > 1 ? (label_h = " hrs ") : total_hr === 0 ? (label_h = " ") : (label_h = " hr ");
                    total_mins > 1
                        ? (label_mins = " mins ")
                        : total_mins === 0
                        ? (label_mins = " ")
                        : (total_mins = " mins ");
                }

                if (total_hr >= 24) {
                    total_days = Number(total_days) + 1;
                    total_hr = Number(total_hr) - 24;
                    total_hr > 1 ? (label_h = " hrs ") : total_hr === 0 ? (label_h = " ") : (label_h = " hr ");
                }
                return <p>{total_days + label_d + total_hr + label_h + total_mins + label_mins}</p>;
            },
            width: 120,
        },
        {
            Header: "Duration (days)",
            Cell: (row: any) => {
                let filter_receieved = [];
                let filter_process = [];
                const filterReceived = dataReceived.filter(
                    (data) =>
                        data.document_id === row.cell.row.original.document_id && data.id === row.cell.row.original.id
                );
                const filterResubmit = dataResubmit.filter(
                    (data) =>
                        data.document_id === row.cell.row.original.document_id && data.id === row.cell.row.original.id
                );

                if (row.cell.row.original.status === "Resubmission not yet acknowledge") {
                    filter_receieved = filterResubmit;
                } else {
                    filter_receieved = filterReceived;
                }

                const filterProcess = dataProcess.filter(
                    (data) =>
                        data.document_id === row.cell.row.original.document_id &&
                        data.action === "process" &&
                        data.id === row.cell.row.original.id &&
                        moment(data.createdAt).isAfter(row.cell.row.original.createdAt)
                );

                const filterReProcess = dataProcess.filter(
                    (data) =>
                        data.document_id === row.cell.row.original.document_id &&
                        data.action === "re-process" &&
                        data.id === row.cell.row.original.id &&
                        moment(data.createdAt).isAfter(row.cell.row.original.createdAt)
                );

                if (row.cell.row.original.status === "Resubmission not yet acknowledge") {
                    filter_process = filterReProcess;
                } else {
                    filter_process = filterProcess;
                }

                let filter_release = [];
                const filterRelease = dataRelease.filter(
                    (data) =>
                        data.document_id === row.cell.row.original.document_id && data.id === row.cell.row.original.id
                );

                const filterReturn = dataReturn.filter(
                    (data) =>
                        data.document_id === row.cell.row.original.document_id &&
                        data.id === row.cell.row.original.id &&
                        moment(data.createdAt).isAfter(row.cell.row.original.createdAt)
                );
                if (filterReturn.length > 0) {
                    filter_release = filterReturn;
                } else {
                    filter_release = filterRelease;
                }

                let day_received = filter_receieved[0]?.updatedAt
                    ? moment(filter_receieved[0].updatedAt).seconds(0).milliseconds(0)
                    : null;

                let day_process = filter_process[0]?.createdAt
                    ? moment(filter_process[0].createdAt).seconds(0).milliseconds(0)
                    : null;

                let day_release = filter_release[0]?.createdAt
                    ? moment(filter_release[0].createdAt).seconds(0).milliseconds(0)
                    : null;

                let duration_process =
                    day_received && day_process ? moment.duration(day_process.diff(day_received)) : null;

                let duration_release =
                    day_process && day_release ? moment.duration(day_release.diff(day_process)) : null;
                let days_release = duration_release ? duration_release.days() : null;
                let days_process = duration_process ? duration_process.days() : null;

                let hr_release = duration_release ? duration_release.hours() : null;
                let hr_process = duration_process ? duration_process.hours() : null;

                let min_release = duration_release ? duration_release.minutes() : null;
                let min_process = duration_process ? duration_process.minutes() : null;

                let total_days = days_release + days_process > 0 ? days_process + days_release : 0;
                let label_d = total_days > 1 ? " days " : " day ";
                let total_hr = hr_release + hr_process > 0 ? hr_release + hr_process : " ";
                let label_h = total_hr > 1 ? " hours " : total_hr === " " ? " " : " hour ";

                let total_mins = min_release + min_process > 0 ? min_release + min_process : " ";
                let label_mins = total_mins > 1 ? " mins " : total_mins === " " ? " " : " min";

                if (total_mins >= 60) {
                    total_hr = Number(total_hr) + 1;
                    total_mins = Number(total_mins) - 60;
                    // label_d = " days ";
                    total_hr > 1 ? (label_h = " hrs ") : total_hr === 0 ? (label_h = " ") : (label_h = " hr ");
                    total_mins > 1
                        ? (label_mins = " mins ")
                        : total_mins === 0
                        ? (label_mins = " ")
                        : (total_mins = " mins ");
                }

                if (total_hr >= 24) {
                    total_days = Number(total_days) + 1;
                    total_hr = Number(total_hr) - 24;

                    label_h = "";
                    label_d = " days";
                }
                let _inDay = Number(total_hr) / 24 + Number(total_mins) / 60 / 24 + total_days;
                let _days = "";
                if (_inDay < 0.001) {
                    _days = "0";
                } else {
                    _days = _inDay.toFixed(2);
                }
                return <p>{_days + label_d}</p>;
            },
            width: 120,
        },
        {
            Header: "Document Route",
            accessor: "name",
            width: 120,
        },
        {
            Header: "Type",
            accessor: "document_type",
            width: 90,
        },
        {
            Header: "Category",
            accessor: "category",
            width: 90,
        },
        {
            Header: "Purpose / Action Required",
            accessor: "purpose",
            width: 150,
        },
        {
            Header: "Subject Matter",
            accessor: "subject_matter",
            width: 90,
        },
        {
            Header: "Status",
            accessor: "status",
            Cell: (row: any) => {
                let filters = [];
                let doc_pending = true;
                let doc_release = false;
                let doc_process = false;
                const filterProcess = dataProcess.filter(
                    (data) =>
                        data.document_id === row.cell.row.original.document_id &&
                        data.id === row.cell.row.original.id &&
                        moment(data.createdAt).isAfter(row.cell.row.original.createdAt)
                );
                const filterRelease = dataRelease.filter(
                    (data) =>
                        data.document_id === row.cell.row.original.document_id && data.id === row.cell.row.original.id
                );

                const filterReturn = dataReturn.filter(
                    (data) =>
                        data.document_id === row.cell.row.original.document_id &&
                        data.id === row.cell.row.original.id &&
                        moment(data.createdAt).isAfter(row.cell.row.original.createdAt)
                );

                const filterData = data.filter(
                    (data) =>
                        data.id === row.cell.row.original.id && data.document_id === row.cell.row.original.document_id
                );

                if (filterReturn.length > 0 && doc_pending === true) {
                    filters = filterReturn;
                    doc_release = true;
                    doc_pending = false;
                }

                if (filterRelease.length > 0 && doc_pending === true) {
                    filters = filterRelease;
                    doc_release = true;
                    doc_pending = false;
                }

                if (filterProcess.length > 0 && doc_release === false) {
                    filters = filterProcess;
                    doc_process = true;
                    doc_pending = false;
                }

                if (doc_pending) {
                    filters = filterData;
                }

                return (
                    <div>
                        {filters.slice(0, 1).map((details: any, index: number) => {
                            return (
                                <p key={index} style={{ color: "black" }}>
                                    {details.status}
                                </p>
                            );
                        })}
                    </div>
                );
            },
            width: 90,
        },
    ];

    const base64ImgH = dataHeader;
    const base64ImgF = ISOimg;

    const handlePrintOpen = () => {
        const footer = Array.from(
            document.getElementsByClassName("MuiTableFooter-root") as HTMLCollectionOf<HTMLElement>
        );
        footer.forEach((element) => {
            element.outerHTML = element.style.display = "none";
        });
        doc.autoTable({
            columnStyles: {
                0: { halign: "center", cellWidth: 90 },
                1: { halign: "left", cellWidth: 80 },
                2: { halign: "left", cellWidth: 60 },
                3: { halign: "left", cellWidth: 60 },
                4: { halign: "left", cellWidth: 60 },
                5: { halign: "left", cellWidth: 60 },
                6: { halign: "left", cellWidth: 53 },
                7: { halign: "left", cellWidth: 53 },
                8: { halign: "left", cellWidth: 80 },
                9: { halign: "left", cellWidth: 60 },
                10: { halign: "left", cellWidth: 60 },
                11: { halign: "left", cellWidth: 60 },
                12: { halign: "left", cellWidth: 100 },
                13: { halign: "left", cellWidth: 73 },
            },
            margin: { top: 140, bottom: 110 },
            didDrawPage: function (data) {
                // Header
                doc.setFontSize(20);
                doc.setTextColor(40);
                // Old English Text MT
                doc.setFont("Old-English-Text-MT_33641", "bold");
                doc.text("University of Southeastern Philippines", 355, 100);
                doc.addFont("ComicSansMS", "Comic Sans", "normal");
                doc.setFont("Comic Sans");
                doc.setFontSize(13);
                doc.text("Document Tracking System", 450, 120);
                doc.text("Uniwide(Outgoing)", 470, 135);
                if (base64ImgH) {
                    doc.addImage(base64ImgH, "JPEG", 480, 20, width - 935, height - 550);
                }
                doc.addFont("ComicSansMS", "Comic Sans", "bold");
                doc.setFont("Comic Sans");
                doc.text(
                    "_______________________________________________________________________________________________________________",
                    50,
                    540
                );
                doc.setFontSize(10);
                doc.text("WE BUILD DREAM WITHOUT LIMITS", 70, 553);
                doc.addFont("ComicSansMS", "Comic Sans", "normal");
                doc.setFont("Comic Sans");
                if (base64ImgF) {
                    doc.addImage(base64ImgF, "JPEG", 900, 520, width - 945, height - 525);
                }
                doc.setFontSize(8);
                doc.setFont("times");
                doc.text("Address: University of Southeastern Philippines", 70, 560);
                doc.text("Telephone: (082) 227-8192 local 271", 300, 560);
                doc.text("Iñigo St., Bo Obrero, Davao City", 100, 567);
                doc.text("Website: www.dts.usep.edu.ph", 300, 567);
                doc.text("Philippines 8000", 100, 574);
                doc.text("Email:sdmd@usep.edu.ph", 300, 574);
                data.settings.margin.top = 135;
                doc.setLineWidth(1.5);
            },
            styles: {
                // columnWidth: "wrap",
            },
            headerStyles: {
                //columnWidth: 'wrap',

                fillColor: [128, 0, 0],
                //textColor: [78, 53, 73], //Black
            },
            html: "#ReportTable",
            theme: "grid",

            includeHiddenHtml: false,
        });
        let now = moment().format("MM/DD/YYYY").toString();
        doc.save("DTS_Report_" + now + ".pdf");
        window.location.reload();
    };

    return React.useMemo(
        () => (
            <div className={classes.root}>
                <div style={{ overflow: "auto" }}>
                    <Row>
                        <Col style={{ textAlign: "right" }}>
                            <Button size="sm" outline color="primary" onClick={handlePrintOpen}>
                                <PrintIcon />
                                Print
                            </Button>
                        </Col>
                    </Row>
                    <TableContainer
                        columns={columns}
                        data={data}
                        isLoading={
                            isLoading ||
                            isLoadingProcess ||
                            isLoadingRelease ||
                            isLoadingReceived ||
                            isLoadingReturn ||
                            isLoadingResubmit
                        }
                        id="ReportTable"
                    />
                </div>
            </div>
        ),
        // Trigger if anything changes
        [data, dataProcess, dataRelease, dataReceived]
    );
};

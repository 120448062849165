import React from "react";
import Swal from "sweetalert2";

import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

import { EnhancedTable } from "../../../components/Table/EnhancedTable";
import { UserModal } from "./UserModal";
import { useAdmin } from "../hooks/useUsers";
import { useDelete } from "../hooks/useDelete";
import { useEmployees } from "../../Correspondence/Outgoing/hooks/useEmployees";
// import { useDepartments } from "../../Correspondence/Outgoing/hooks/useDepartments";
import { useDepartmentsListAdmin } from "../../Correspondence/Outgoing/hooks/useDepartmentListAdmin";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(1),
            width: 10,
            height: 10,
        },
        extendedIcon: {
            marginRight: theme.spacing(1),
        },
        root: {
            width: "100%",
        },
        paper: {
            width: "100%",
            marginBottom: theme.spacing(2),
        },
    })
);

export const UserTable: React.FC = () => {
    const classes = useStyles();
    const info = JSON.parse(localStorage.getItem("state"));
    const { data: dataEmployees, isLoading: isLoadingEmployees } = useEmployees();
    const { data: dataDepartments, isLoading: isLoadingDepartments } = useDepartmentsListAdmin();

    const { data: dataUsers = [], isLoading: isLoadingUsers } = useAdmin();
    const [deleteUser] = useDelete();
    const columns: any = [
        {
            Header: "Email",
            accessor: "email",
            width: 250,
        },
        {
            Header: "Full Name",
            accessor: "full_name",
        },
        {
            Header: "Type",
            accessor: "type",
            width: 90,
        },
        {
            Header: "Department",
            accessor: "department_id",
            width: 250,
            Cell: (row: any) => {
                const data = !isLoadingDepartments
                    ? dataDepartments.find((rs: any) => rs.DepartmentID === row.cell.row.original.department_id)
                    : "";
                return <>{row.cell.row.original.department_name}</>;
            },
        },
        {
            Header: "Created By",
            accessor: "createdBy",
            Cell: (row: any) => {
                const data = !isLoadingEmployees
                    ? dataEmployees.data.find((rs: any) => rs.EmployeeID === row.cell.row.original.createdBy)
                    : "";
                return <>{data.EmployeeName}</>;
            },
        },
        {
            headerClassName: "user-action",
            Header: "Action",
            accessor: "action",
            Cell: (row: any) => (
                <>
                    <IconButton
                        ria-label="delete"
                        className={classes.margin}
                        onClick={() => {
                            handleDeleteIcon(row.cell.row.original.id);
                        }}
                    >
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                </>
            ),
            width: 200,
        },
    ];

    const handleDeleteIcon = (id: string) => {
        Swal.fire({
            title: "Warning!",
            text: "Are you sure you want to delete?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                const details = {
                    id: id,
                    deleted_by: info.full_name,
                };
                deleteUser.mutate(JSON.stringify(details));
                Swal.fire("Deleted!", "Successfully deleted.", "success");
            }
        });
    };

    if (isLoadingUsers) {
        return <span>Loading...</span>;
    }

    return (
        <div className={classes.root}>
            <EnhancedTable AddModal={UserModal} columns={columns} data={dataUsers} view={true} />
        </div>
    );
};

import axios from "axios";
import { useContext } from "react";
import { useQuery } from "react-query";
import { Context } from "../../../../context/Context";

export const fetchDocument = async (data) => {
    const results = await axios
        .get(`${process.env.REACT_APP_HOST}/api/document/assigned/${data.queryKey[1]}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + data.queryKey[2],
            },
        })
        .then((res) => res.data)
        .catch((err) => {
            if (err.response) {
                return false;
            }
        });

    return results;
};

export const useDocument = (id) => {
    const { state } = useContext(Context);

    return useQuery(["outgoing_documents", id, state.token], fetchDocument, {
        initialData: () => [{ data: [] }],
    });
};

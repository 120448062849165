import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toastify } from "../../../../components/Toastr";
import { Context } from "../../../../context/Context";

export const useSaveDocuments = (setDisabled?: any, setOpen?: any) => {
  const { state } = useContext(Context);
  const cache = useQueryClient();
  const mutation = useMutation<AxiosResponse<any>, unknown, any, unknown>(
    (values: any) => {
      return axios.post(
        `${process.env.REACT_APP_HOST}/api/document/create`,
        values,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + state.token,
          },
        }
      );
    },
    {
      onSuccess: () => {
        cache.refetchQueries("outgoing_documents");
        cache.refetchQueries("outgoing_documents_dept");
        cache.refetchQueries("retrieve_files");
        cache.setQueryData("svg", 123);
        toastify(200, "You've successfully saved the outgoing.");
        setDisabled(false);
        setOpen(false);
      },
    }
  );

  return [mutation];
};

import React, { useState } from "react";
import Select from "react-select";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "reactstrap";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { useDepartments } from "../hooks/useDepartments";
import { useEmployees } from "../../Correspondence/Outgoing/hooks/useEmployees";
import { useSaveDocument } from "../hooks/useSaveDepartment";
import { useEmployeeDetails } from "../../Correspondence/Outgoing/hooks/useEmployeeDetails";

interface EmployeeeDetailsProps {
    EmployeeID: number;
    LastName: string;
    FirstName: string;
    MiddleName: string;
    Email: string;
}

export const DepartmentModal: React.FC = () => {
    const info = JSON.parse(localStorage.getItem("state"));

    const { data, isLoading } = useDepartments();
    const { data: dataEmployees, isLoading: isLoadingEmployees } = useEmployees();
    const { data: dataEmployeeDetails, isLoading: isLoadingEmployeeDetails } = useEmployeeDetails();
    const [create] = useSaveDocument();
    const [details, setDetails] = useState({
        created_by: info.full_name,
        name: "",
        login_id: 0,
        email: "",
        department: [],
    });
    const customStyles = {
        container: (styles: any) => ({
            ...styles,
            marginTop: 20,
            marginBottm: 20,
            height: 35,
        }),
    };

    const [open, setOpen] = useState<boolean>(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleSelectDepartment = (event) => {
        let department = [];
        // eslint-disable-next-line array-callback-return
        event.map((row: any) => {
            department.push({
                label: row.label,
                value: row.value,
            });
        });

        setDetails({ ...details, department: department });
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        create.mutate(details);
    };

    const handleSelect = (event) => {
        setDetails({
            ...details,
            email: event.employeeDetails.Email,
            name: event.label,
            login_id: event.value,
        });
    };
    return (
        <div>
            <Tooltip title="Add">
                <IconButton aria-label="add" onClick={handleClickOpen}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    <DialogTitle id="form-dialog-title">Add Action</DialogTitle>
                    <DialogContent style={{ height: 300, width: 500, overflow: "hidden" }}>
                        <DialogContentText>Assign Department</DialogContentText>
                        {!isLoadingEmployees ? (
                            <Select
                                name="employees"
                                styles={customStyles}
                                options={dataEmployees.data.map((row) => ({
                                    value: row.EmployeeID,
                                    label: row.EmployeeName,
                                    employeeDetails: !isLoadingEmployeeDetails
                                        ? dataEmployeeDetails.find(
                                              (rs: EmployeeeDetailsProps) => rs.EmployeeID === row.EmployeeID
                                          )
                                        : "",
                                }))}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder={"Search Employee's"}
                                menuPlacement="auto"
                                onChange={handleSelect}
                                // onChange={(event) => {
                                //   setDetails({
                                //     ...details,
                                //     name: event ? event.label : null,
                                //   });

                                // }}
                            />
                        ) : (
                            <Select
                                name="employees"
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder={"Fetching...."}
                            />
                        )}
                        {!isLoading ? (
                            <Select
                                isMulti
                                name="department"
                                styles={customStyles}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder={"Select Assign Department"}
                                options={data.map((row) => ({
                                    value: row.DepartmentID,
                                    label: row.DepartmentName + "-" + row.CampusName,
                                }))}
                                onChange={handleSelectDepartment}
                            />
                        ) : (
                            <Select
                                isMulti
                                name="department"
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder={"Fetching Route..."}
                                onChange={handleSelectDepartment}
                            />
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="danger " outline>
                            Cancel
                        </Button>
                        <Button type="submit" color="primary" outline>
                            Save
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
};

/* eslint-disable array-callback-return */
import React, { useContext, useState } from "react";
import Select from "react-select";
import { Row, Col } from "reactstrap";
import { EnchancedModal } from "../../../../components/Modal/EnhancedModal";
import { toastify } from "../../../../components/Toastr";
import { Context } from "../../../../context/Context";
// import { useDepartments } from "../../Outgoing/hooks/useDepartments";
import { useDocument } from "../../Outgoing/hooks/useDocument";
import { useEmployees } from "../../Outgoing/hooks/useEmployees";
import { useSaveAdditional } from "../hooks/useSaveAdditional";
import { useDepartmentsList } from "../../Outgoing/hooks/useDepartmentList";
// import Checkbox from "@material-ui/core/Checkbox";
// import { useEmployeesByDept } from "../hooks/useRetrieveUser";
import TextField from "@material-ui/core/TextField";
interface Props {
    document_id: any;
    additionalOpen: any;
    setAdditionalOpen: any;
    isDept: boolean;
}

const customStyles = {
    container: (styles) => ({
        ...styles,
        marginTop: 20,
    }),
};

export const IncomingAdditionalModal: React.FC<Props> = ({
    document_id,
    additionalOpen,
    setAdditionalOpen,
    isDept,
}) => {
    const { state } = useContext(Context);
    const [create] = useSaveAdditional();
    const { data, isLoading } = useEmployees();
    // const { data: dataDepartment } = useDepartments();
    const { data: dataDepartment } = useDepartmentsList();
    const { data: dataDocument, isLoading: isLoadingDocument } = useDocument(document_id);

    const [lists, setLists] = useState<any>({
        document_id: "",
        additional_routes: [],
        createdBy: state.employee_id,
        remarks: "",
    });

    // const { data: dataUser = [] } = useEmployeesByDept();
    const [dept, setDept] = useState<any>(true);

    const handleRemarks =
        (name) =>
        ({ target: { value } }) => {
            setLists({ ...lists, [name]: value });
        };

    const handleChange = (event: any) => {
        let route = [];

        event.map((row: any) => {
            let status = "Not yet acknowledged";
            if (route.length > 0) {
                status = "Pending";
            }

            route.push({
                label: isDept ? row.label : null,
                value: isDept ? row.value : null,
                department: row.department.DepartmentID,
                department_name: row.department.DepartmentName,
                status: status,
            });
        });

        setLists({
            ...lists,
            additional_routes: route,
            document_id: document_id,
        });
    };

    const handleClose = () => {
        setAdditionalOpen(false);
        setDept(true);
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleCheckDepartment = (event) => {
        setDept(event.target.checked);
    };

    const handleSubmit = () => {
        if (lists.additional_routes.length === 0) {
            return toastify(199, "Route's is required.");
        }
        create.mutate(lists);
        setAdditionalOpen(false);
    };

    const customStylesHeader = {
        container: (styles) => ({
            ...styles,
            marginTop: 20,
            width: 420,
        }),
        menu: (styles) => ({
            ...styles,
            zIndex: 9999999,
        }),
    };

    const listRoutes = () => {
        if (isDept) {
            return data.data
                .filter((dept) => dept.DepartmentID === state.department_id)
                .map((row) => ({
                    value: row.EmployeeID,
                    label: row.EmployeeName,
                    department: !isLoadingDocument
                        ? dataDepartment.find((rs: any) => rs.DepartmentID === row.DepartmentID)
                        : "",
                    isDisabled: dataDocument.find((rs: any) => {
                        if (rs.name_id === row.EmployeeID) {
                            return true;
                        }
                        return false;
                    }),
                }));
        } else {
            return dept
                ? dataDepartment.map((row) => ({
                      value: row.DepartmentID,
                      label: row.DepartmentName + "-" + row.CampusName,
                      department: dataDepartment.find((rs: any) => rs.DepartmentID === row.DepartmentID),
                      isDisabled: dataDocument.find((rs: any) => {
                          if (Number(rs.department) === row.DepartmentID) {
                              return true;
                          }
                          return false;
                      }),
                  }))
                : data.data.map((row) => ({
                      value: row.EmployeeID,
                      label: row.EmployeeName,
                      department: !isLoadingDocument
                          ? dataDepartment.find((rs: any) => rs.DepartmentID === row.DepartmentID)
                          : "",
                      isDisabled: dataDocument.find((rs: any) => {
                          if (Number(rs.department) === row.DepartmentID) {
                              return true;
                          }
                          return false;
                      }),
                  }));
        }
    };

    return (
        <div>
            <EnchancedModal
                open={additionalOpen}
                setOpen={setAdditionalOpen}
                title={"Additional Route's"}
                subtitle={"Fill up details"}
                view={false}
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                width={550}
                height={250}
                overflow={"hidden"}
                position={""}
                display={"block"}
            >
                <Row>
                    <Col lg="10">
                        {!isLoading ? (
                            <Select
                                styles={customStylesHeader}
                                isMulti
                                name="colors"
                                options={listRoutes()}
                                getOptionLabel={(option) => {
                                    return isDept
                                        ? `${option.label} - ${option.department.DepartmentName}- ${option.department.CampusName}`
                                        : `${option.label} - ${option.department.CampusName}`;
                                }}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder={"Select Route's"}
                                onChange={handleChange}
                                maxMenuHeight={130}
                            />
                        ) : (
                            <Select
                                styles={customStyles}
                                isMulti
                                name="colors"
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder={"Fetching Route's"}
                                onChange={handleChange}
                            />
                        )}
                    </Col>
                    {/* {!isDept ? (
                        <Checkbox
                            style={{ float: "inline-end", top: "10px", left: "30px" }}
                            checked={dept}
                            inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                            onChange={handleCheckDepartment}
                        />
                    ) : (
                        ""
                    )} */}
                    <TextField
                        multiline
                        rows={4}
                        rowsMax={"auto"}
                        margin="dense"
                        label="Remarks"
                        type="remarks"
                        variant="outlined"
                        value={lists.remarks}
                        onChange={handleRemarks("remarks")}
                        style={{ top: "10px", left: "13px", width: "450px" }}
                    />
                </Row>
            </EnchancedModal>
        </div>
    );
};

import axios from "axios";
import { useContext } from "react";
import { useQuery } from "react-query";
import { Context } from "../../../../context/Context";

export const fetchAssignAdmin = async (data) => {
  const results = await axios
    .get(`${process.env.REACT_APP_HOST}/api/admin/department`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + data.queryKey[1],
      },
    })
    .then((res) => res.data.data);

  return results;
};

export const useEmployeesAdmin = () => {
  const { state } = useContext(Context);

  return useQuery(["assignadminr", state.token], fetchAssignAdmin, {
    initialData: () => [],
  });
};

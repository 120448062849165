import React, { useContext, useState } from "react";
import { Link } from 'react-router-dom';
import DownIcon from "mdi-react/ChevronDownIcon";
import { Collapse } from "reactstrap";
import { Context } from "../../../context/Context";

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

export const TopbarProfile: React.FC = () => {
    const { state } = useContext(Context)
    const [collapse, setCollapse] = useState<boolean>(false);

    const Toggle = () => {
        setCollapse((prevState) => !prevState);
    };

    const Logout = () => {
        localStorage.setItem("state", "");
    };
    
    return (
        <div className="topbar__profile">
            <button type="button" className="topbar__avatar" onClick={Toggle}>
                <img className="topbar__avatar-img" src={Ava} alt="avatar" />
                <p className="topbar__avatar-name">{state.full_name}</p>
                <DownIcon className="topbar__icon" />
            </button>
            {collapse && (
                <button type="button" className="topbar__back" onClick={Toggle} />
            )}
            <Collapse isOpen={collapse} className="topbar__menu-wrap">
                <div className="topbar__menu">
                    <Link
						className="topbar__link"
						to="/"
						onClick={Logout}
					>
						<span className={`topbar__link-icon lnr lnr-exit`} />
						<p className="topbar__link-title">Log Out</p>
					</Link>
                </div>
            </Collapse>
        </div>
    );
}

import React, { useState } from "react";
import { withRouter } from "react-router-dom";
// @ts-ignore
import classNames from "classnames";
import { Topbar } from "./topbar/Topbar";
import { Sidebar } from "./sidebar/Sidebar";

const Layout: React.FC = () => {
    const [sidebarShow, setSidebarShow] = useState(false);
    const [sidebarCollapse, setSidebarCollapse] = useState(false);

    const changeSidebarVisibility = () => {
        setSidebarCollapse((sidebarCollapse) => !sidebarCollapse);
    };

    const changeMobileSidebarVisibility = () => {
        setSidebarShow((sidebarShow) => !sidebarShow);
    };

    const layoutClass = classNames({
        layout: true,
        "layout--collapse": sidebarCollapse,
    });

    return (
        <div className={layoutClass}>
            <Topbar 
                changeMobileSidebarVisibility={changeMobileSidebarVisibility} 
                changeSidebarVisibility={changeSidebarVisibility} 
            />
            <Sidebar 
                sidebarShow={sidebarShow} 
                sidebarCollapse={sidebarCollapse} 
                changeMobileSidebarVisibility={changeMobileSidebarVisibility} 
            />
        </div>
    );
};

export default withRouter(Layout);

import axios from "axios";
import { useContext } from "react";
import { useQuery } from "react-query";
import { Context } from "../../../../context/Context";

export const fetchFiles = async (data) => {
    const results = await axios
        .get(`${process.env.REACT_APP_HOST}/api/files/all`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + data.queryKey[1],
            },
        })
        .then((res) => res.data)
        .catch((err) => {
            if (err.response) {
                return false;
            }
        });

    return results;
};

export const useFiles = () => {
    const { state } = useContext(Context);
    return useQuery(["retrieve_files", state.token], fetchFiles, {
        initialData: () => [{ data: [] }],
    });
};

import React, { useContext, useState } from "react";
import classNames from "classnames";
import { Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import { Context } from "../../../../context/Context";
import { ArchivesTable } from "./ArchivesTable";
import { ArchivesTableByDept } from "./ArchivesTableByDept";

import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const moment = require("moment");
const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    dateField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
}));
export const ArchivesTabs: React.FC = () => {
    const [activeTab, setActiveTab] = useState("1");
    const { state } = useContext(Context);
    const toggle = ({ tab }: { tab: any }) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    const classes = useStyles();
    const current = moment(new Date()).format("YYYY-MM-DD").toString();

    const [fromDate, setFromDate] = useState<string>(current);
    const [toDate, setToDate] = useState<string>(current);
    const [isFilter, setFilter] = useState<boolean>(true);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleIsFilter = (event) => {
        setFilter(event.target.checked);
        setFromDate(null);
        setToDate(null);
    };
    return (
        <div className="tabs__wrap">
            <span>Filter archived document date range</span>
            {/* <Checkbox
                inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                checked={isFilter}
                onChange={handleIsFilter}
            /> */}
            {isFilter ? (
                <TextField
                    id="dateFrom"
                    size="small"
                    label="Date from"
                    type="date"
                    defaultValue={current}
                    onChange={(event) => {
                        setFromDate(event.target.value);
                    }}
                    className={classes.dateField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            ) : (
                ""
            )}

            {isFilter ? (
                <TextField
                    id="dateTo"
                    size="small"
                    label="Date to"
                    type="date"
                    defaultValue={current}
                    className={classes.dateField}
                    onChange={(event) => {
                        setToDate(event.target.value);
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            ) : (
                ""
            )}
            <Nav tabs>
                <NavItem>
                    <NavLink className={classNames({ active: activeTab === "1" })} onClick={() => toggle({ tab: "1" })}>
                        <Row>
                            UNIWIDE
                            <span
                                className="ml-1"
                                style={{
                                    fontSize: "0.7rem",
                                    fontWeight: 700,
                                    marginTop: -5,
                                    color: "red",
                                }}
                            >
                                {state.count_archived}
                            </span>
                        </Row>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classNames({ active: activeTab === "2" })} onClick={() => toggle({ tab: "2" })}>
                        <Row>
                            INTERNAL(by office)
                            <span
                                className="ml-1"
                                style={{
                                    fontSize: "0.7rem",
                                    fontWeight: 700,
                                    marginTop: -5,
                                    color: "red",
                                }}
                            >
                                {state.count_archived_dept}
                            </span>
                        </Row>
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <ArchivesTable fromDate={fromDate} toDate={toDate} isFilter={isFilter} />
                </TabPane>
                <TabPane tabId="2">
                    <ArchivesTableByDept fromDate={fromDate} toDate={toDate} isFilter={isFilter} />
                </TabPane>
            </TabContent>
        </div>
    );
};

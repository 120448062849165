import axios from "axios";
import { useContext } from "react";
import { useQuery } from "react-query";
import { Context } from "../../../../context/Context";

export const fetchIncomingDocuments = async (data) => {
    const results = await axios
        .get(`${process.env.REACT_APP_HOST}/api/document/all/incomingByDept/${data.queryKey[1]}/${data.queryKey[3].fromDate}/${data.queryKey[3].toDate}/${data.queryKey[3].isFilter}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + data.queryKey[2],
            },
        })
        .then((res) => res.data.data);

    return results;
};

export const useIncomingDocumentsByDept = (data) => {
    const { state } = useContext(Context);

    return useQuery(["incoming_documents_dept", state.employee_id, state.token,data], fetchIncomingDocuments);
};

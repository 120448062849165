import axios from "axios";
import { useContext } from "react";
import { useQuery } from "react-query";
import { Context } from "../../../../context/Context";

export const fetchIncomingDocuments = async (data) => {
    const results = await axios
        .get(
            `${process.env.REACT_APP_HOST}/api/document/all/incoming/${data.queryKey[1]}/${data.queryKey[3].fromDate}/${data.queryKey[3].toDate}/${data.queryKey[3].isFilter}/${data.queryKey[4]}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + data.queryKey[2],
                },
            }
        )
        .then((res) => res.data.data);

    return results;
};

export const useIncomingDocuments = (data) => {
    const { state } = useContext(Context);

    return useQuery(
        ["incoming_documents", state.employee_id, state.token, data, state.department_id],
        fetchIncomingDocuments
    );
};

import React, { useState, useEffect, useContext } from "react";

import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import ViewIcon from "@material-ui/icons/Visibility";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import AttachmentIcon from "@material-ui/icons/Attachment";

import Swal from "sweetalert2";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import { EnhancedTable } from "../../../../components/Table/EnhancedTable";
import { CorrespondenceModal } from "./OutgoingModal";
import { ViewModal } from "./ViewModal";
import { ViewFileModal } from "./ViewFileModal";
import { ResubmitModal } from "./ResubmitModal";
import { useDocuments } from "../hooks/useDocuments";
import { useFiles } from "../hooks/useRetrieveFiles";
import { Context } from "../../../../context/Context";
import { useCancelDocuments } from "../hooks/useCancelDocuments";

interface Props {
    defaultDepartment: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(1),
            width: 10,
            height: 10,
        },
        extendedIcon: {
            marginRight: theme.spacing(1),
        },
        root: {
            width: "100%",
        },
        paper: {
            width: "100%",
            marginBottom: theme.spacing(2),
        },
    })
);

export const CorrespondenceTable: React.FC<Props> = () => {
    const [cancel] = useCancelDocuments();
    const { state, dispatch } = useContext(Context);
    const classes = useStyles();
    const [open, setOpen] = useState<boolean>(false);
    const [openFile, setFileOpen] = useState<boolean>(false);
    const [isPrint, setPrint] = useState<any>(false);
    const [id, setId] = useState<string>("");
    const [openSubmit, setOpenReSubmit] = useState<boolean>(false);
    const { data = [], isLoading } = useDocuments();
    const {
        data: { data: dataFiles = [] },
        isLoading: isLoadingFiles,
    }: any = useFiles();

    // const return_data = data.filter((data) => data.status === "Returned");

    useEffect(() => {
        if (isLoading === false) {
            dispatch({
                type: "COUNT_OUTGOING_ACTION",
                count_outgoing: data.length,
            });
            dispatch({
                type: "COUNT_RETURN_OUTGOING_ACTION",
                count_outgoing_return: data.filter((data) => data.status === "Returned").length,
            });
            dispatch({
                type: "COUNT_PENDING_OUTGOING_ACTION",
                count_outgoing_pending: data.filter(
                    (data) =>
                        data.status === "Not yet acknowledged" || data.status === "Resubmission not yet acknowledge"
                ).length,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const columns: any = [
        {
            Header: "Reference No.",
            accessor: "reference_no",
            width: 130,
        },
        {
            Header: "Source Office",
            accessor: "source",
            width: 130,
        },
        {
            Header: "Type",
            accessor: "document_type",
            width: 130,
        },
        {
            Header: "Category",
            accessor: "category",
            width: 150,
        },
        {
            Header: "Purpose/Action Required",
            accessor: "purpose",
            width: 150,
        },
        {
            Header: "Subject Matter",
            accessor: "subject_matter",
            width: 150,
        },

        {
            Header: "Document Date",
            accessor: "createdAt",
            Cell: (row: any) => <>{moment(row.cell.value).format("MM/DD/YYYY hh:mm a")}</>,
            width: 120,
        },
        {
            Header: "Received By",
            accessor: "received_by",
            Cell: (row: any) => (
                <>{row.cell.row.original.list_route?.name ? row.cell.row.original.list_route.name : ""}</>
            ),
        },
        {
            Header: "File",
            accessor: "attach_name",
            Cell: (row: any) => {
                const filter = dataFiles.filter((data) => data.document_id === row.cell.row.original.id);
                return (
                    <div>
                        {filter.map((item: any, index: number) => {
                            return (
                                <p key={index} style={{ color: "red" }}>
                                    {item.attach_name ? item.attach_name : "No File"}
                                </p>
                            );
                        })}
                    </div>
                );
            },
        },

        {
            Header: "Status",
            accessor: "status",
            width: 100,
            Cell: (row: any) => (
                <>
                    <p style={{ color: "red" }}>
                        {row.cell.row.original.list_route?.status ? row.cell.row.original.list_route.status : ""}
                    </p>
                </>
            ),
        },
        {
            headerClassName: "correspondence-action",
            Header: "Action",
            accessor: "action",
            Cell: (row: any) => {
                const filter = dataFiles.filter(
                    (data) => data.document_id === row.cell.row.original.id && data.attach_name !== null
                );

                return (
                    <div>
                        <Tooltip title="Resubmit">
                            <span>
                                <IconButton
                                    ria-label="resubmit"
                                    className={classes.margin}
                                    onClick={() => {
                                        handleResubmit(row.cell.row.original.id, filter.length);
                                    }}
                                    disabled={
                                        row.cell.row.original.list_route.status ===
                                            "Resubmission not yet acknowledge" ||
                                        row.cell.row.original.list_route.status === "Processing" ||
                                        row.cell.row.original.list_route.status === "Not yet acknowledged"
                                            ? true
                                            : false
                                    }
                                >
                                    <AttachmentIcon fontSize="small" />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title="View">
                            <IconButton
                                ria-label="view"
                                className={classes.margin}
                                onClick={() => {
                                    handleViewIcon(row.cell.row.original.id);
                                }}
                            >
                                <ViewIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Download">
                            <span>
                                <IconButton
                                    ria-label="download"
                                    className={classes.margin}
                                    onClick={() => {
                                        handleViewFiles(row.cell.row.original.id);
                                    }}
                                    disabled={!filter.length ? true : false}
                                >
                                    <CloudDownloadIcon fontSize="small" />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <span>
                                <IconButton
                                    ria-label="delete"
                                    className={classes.margin}
                                    onClick={() => {
                                        handleDelete(row.cell.row.original.id);
                                    }}
                                    disabled={row.cell.row.original.status === "Processing" ? true : false}
                                >
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </div>
                );
            },
            width: 200,
        },
    ];

    const handleDelete = (document_id: string) => {
        Swal.fire({
            title: "Warning!",
            text: "Are you sure you want to delete?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                cancel.mutate({
                    document_id: document_id,
                    createdBy: state.employee_id,
                });
                Swal.fire("Deleted!", "Your file has been deleted.", "success");
            }
        });
    };

    const handleViewIcon = (id: string) => {
        setId(id);
        setOpen(true);
    };

    const handleResubmit = (id, file_count: number) => {
        setId(id);
        setOpenReSubmit(true);

        if (file_count) {
            setPrint(false);
        } else {
            setPrint(true);
        }
    };

    useEffect(() => {
        if (!open) {
            setId("");
        }
    }, [open]);

    if (isLoading || isLoadingFiles) {
        return <span>Loading...</span>;
    }
    const handleViewFiles = (id: string) => {
        setId(id);
        setFileOpen(true);
    };

    return (
        <div className={classes.root}>
            <EnhancedTable AddModal={CorrespondenceModal} columns={columns} data={data} view={true} />
            {id ? <ViewModal open={open} setOpen={setOpen} id={id} /> : ""}
            {id ? <ViewFileModal openFile={openFile} setFileOpen={setFileOpen} id={id} /> : ""}
            {id ? (
                <ResubmitModal openSubmit={openSubmit} isPrint={isPrint} setOpenReSubmit={setOpenReSubmit} id={id} />
            ) : (
                ""
            )}
        </div>
    );
};

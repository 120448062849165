import React from "react";
import { Container, Col, Card, CardBody, Row } from "reactstrap";
import { ApprovedTabs } from "./views/ApprovedTabs";

export const CorrespondenceApproved: React.FC = () => {
    return (
        <Container className="dashboard">
            <Row>
                <Col md={12}>
                    <h3 className="page-title">Correspondence Approved/Released</h3>
                </Col>
            </Row>
            <Row>
                <Card>
                    <CardBody>
                        <div className="tabs tabs--bordered-bottom">
                            <ApprovedTabs />
                        </div>
                    </CardBody>
                </Card>
            </Row>
        </Container>
    );
};

import React from "react";
import { Container, Col, Card, CardBody, Row } from "reactstrap";

import { ManagerTabs } from "./views/ManagerTabs";

export const Manager: React.FC = () => {
    return (
        <Container className="dashboard">
            <Row>
                <Col md={12}>
                    <h3 className="page-title">{"Manager"}</h3>
                </Col>
            </Row>
            <Row>
                <Card>
                    <CardBody>
                        <div className="tabs tabs--bordered-bottom">
                            <ManagerTabs />
                        </div>
                    </CardBody>
                </Card>
            </Row>
        </Container>
    );
};

import React, { useContext } from "react";
import { EnchancedModal } from "../../../../components/Modal/EnhancedModal";
import { EnhancedTable } from "../../../../components/Table/EnhancedTable";
import IconButton from "@material-ui/core/IconButton";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { useFiles } from "../hooks/useRetrieveFiles";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import Swal from "sweetalert2";
import axios from "axios";
import FileDownload from "js-file-download";
import { Context } from "../../../../context/Context";
import { useProcess } from "../hooks/useProcess";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(1),
            width: 10,
            height: 10,
        },
        extendedIcon: {
            marginRight: theme.spacing(1),
        },
        root: {
            width: "100%",
        },
        paper: {
            width: "100%",
            marginBottom: theme.spacing(2),
        },
    })
);

interface Props {
    openFile: boolean;
    setFileOpen: any;
    id: any;
}

const url: any = process.env.REACT_APP_HOST;

export const ViewFileModal: React.FC<Props> = ({ openFile, setFileOpen, id }) => {
    const { state } = useContext(Context);
    const {
        data: { data: dataFiles = [] },
        isLoading,
    }: any = useFiles();
    const newStateFiles = dataFiles.filter((data) => data.document_id === id);
    const classes = useStyles();
    const [process] = useProcess();
    const columns: any = React.useMemo(
        () => [
            {
                Header: "File",
                accessor: "id",
                width: 190,
                Cell: (row: any) => {
                    const attach_name = row.cell.row.original.attach_name;
                    return <p style={{ color: "#d65238" }}>{attach_name}</p>;
                },
            },
            {
                headerClassName: "correspondence-action",
                Header: "Download",
                accessor: "attach_name",
                Cell: (row: any) => (
                    <>
                        <IconButton
                            ria-label="download"
                            className={classes.margin}
                            onClick={() => {
                                handleDownload(
                                    row.cell.row.original.id,
                                    row.cell.row.original.attach_name,
                                    row.cell.row.original.document_id
                                );
                            }}
                        >
                            <CloudDownloadIcon fontSize="small" />
                        </IconButton>
                    </>
                ),
                width: 200,
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleDownload = async (id: string, name: string, document_id: string) => {
        Swal.fire({
            title: "Information!",
            text: "Are you sure you want to process?",
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, process it!",
            customClass: {
                container: "view_modal",
            },
        }).then(async (result) => {
            if (result.isConfirmed) {
                const results = await axios({
                    url: `${url}/api/document/download/${name}/${document_id}`,
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + state.token,
                    },
                    method: "GET",
                    responseType: "blob",
                }).then((res) => {
                    const path = url + "/files/" + document_id + "/" + name;
                    if (res.data.type === "application/pdf") {
                        window.open(path);
                    } else {
                        FileDownload(res.data, name);
                    }

                    return res;
                });

                if (results.status === 200) {
                    process.mutate({
                        id: id,
                        document_id: document_id,
                        employee_id: state.employee_id,
                        full_name: state.full_name,
                    });
                }
            }
        });
    };
    const handleClose = () => {
        setFileOpen(false);
    };

    if (isLoading) {
        return <span>Loading...</span>;
    }

    return (
        <div>
            <EnchancedModal
                open={openFile}
                setOpen={setFileOpen}
                title={"Files"}
                subtitle={"List of Files"}
                view={true}
                handleClose={handleClose}
            >
                <EnhancedTable columns={columns} data={newStateFiles} view={false} />
            </EnchancedModal>
        </div>
    );
};

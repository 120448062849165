import React from "react";
import Swal from "sweetalert2";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";

import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { EnhancedTable } from "../../../components/Table/EnhancedTable";
import { ActionModal } from "./ActionModal";
import { useAction } from "../hooks/useAction";
import { useDeleteAction } from "../hooks/useDeleteAction";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(1),
            width: 10,
            height: 10,
        },
        extendedIcon: {
            marginRight: theme.spacing(1),
        },
        root: {
            width: "100%",
        },
        paper: {
            width: "100%",
            marginBottom: theme.spacing(2),
        },
    })
);

export const ActionTable = () => {
    const [deleteAction] = useDeleteAction();
    const info = JSON.parse(localStorage.getItem("state"));
    const classes = useStyles();
    const { data = [], isLoading } = useAction();

    const columns: any = React.useMemo(
        () => [
            {
                Header: "Purpose/Action ID",
                accessor: "id",
                width: 100,
            },
            {
                Header: "Purpose/Action Name",
                accessor: "name",
                width: 250,
            },
            {
                Header: "Created By",
                accessor: "created_by",
                width: 200,
            },
            {
                headerClassName: "manager-type-action",
                Header: "Action",
                accessor: "action",
                Cell: (row: any) => (
                    <>
                        <Tooltip title="Delete">
                            <IconButton
                                ria-label="delete"
                                className={classes.margin}
                                onClick={() => {
                                    handleDelete(row.cell.row.original.id);
                                }}
                                disabled={info.role === "Super Admin" ? false : true}
                            >
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </>
                ),
                width: 100,
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleDelete = (id) => {
        Swal.fire({
            title: "Warning!",
            text: "Are you sure you want to delete?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                const details = {
                    id: id,
                    deleted_by: info.full_name,
                };
                deleteAction.mutate(JSON.stringify(details));
                Swal.fire("Deleted!", "Your file has been deleted.", "success");
            }
        });
    };

    if (isLoading) {
        return <span>Loading...</span>;
    }

    return (
        <div className={classes.root}>
            <EnhancedTable AddModal={ActionModal} columns={columns} data={data} view={true} />
        </div>
    );
};

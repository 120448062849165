import axios from "axios";
import { useContext } from "react";
import { useQuery } from "react-query";
import { Context } from "../../../../context/Context";

export const fetchDepartments = async (data) => {
    const results = await axios
        .get(`${process.env.REACT_APP_HOST}/api/department/all`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + data.queryKey[1],
            },
        })
        .then((res) => res.data.data);

    return results;
};

export const useDepartments = () => {
    const { state } = useContext(Context);
    return useQuery(["departments", state.token], fetchDepartments);
};

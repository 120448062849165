import axios from "axios";
import { useContext } from "react";
import { useQuery } from "react-query";
import { Context } from "../../../../context/Context";

export const fetchReturnDept = async (data) => {
  const results = await axios
    .get(`${process.env.REACT_APP_HOST}/api/dept/return/outgoing`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + data.queryKey[2],
      },
    })
    .then((res) => res.data.data);

  return results;
};

export const useReturnTrailDept = () => {
  const { state } = useContext(Context);

  return useQuery(
    ["audit_return_dept", state.employee_id, state.token],
    fetchReturnDept,
    {
      initialData: () => [],
    }
  );
};

import React, { useState } from "react";

import AddIcon from "@material-ui/icons/Add";
// import Button from "@material-ui/core/Button";
import { Button } from "reactstrap";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import { toastify } from "../../../components/Toastr";

import { useSaveType } from "../hooks/useSaveType";

export const TypeModal: React.FC = () => {
  const [create] = useSaveType();
  const info = JSON.parse(localStorage.getItem("state"));
  const [details, setDetails] = useState({
    name: "",
    created_by: info.full_name,
  });

  const [open, setOpen] = useState<boolean>(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange =
    (name) =>
    ({ target: { value } }) => {
      setDetails({ ...details, [name]: value });
    };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!details.name.trim()) {
      return toastify(199, "Please input Type");
    }
    create.mutate(details);
    setOpen(false);
  };

  return (
    <div>
      <Tooltip title="Add">
        <IconButton aria-label="add" onClick={handleClickOpen}>
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <DialogTitle id="form-dialog-title">Add Type</DialogTitle>
          <DialogContent
            style={{ height: 100, width: 300, overflow: "hidden" }}
          >
            <DialogContentText>Fill up type details.</DialogContentText>
            <TextField
              margin="dense"
              label="Type"
              type="text"
              fullWidth
              value={details.name}
              onChange={handleChange("name")}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="danger " outline>
              Cancel
            </Button>
            <Button type="submit" color="primary" outline>
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

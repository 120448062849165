import React from "react";
import Scrollbar from "react-smooth-scrollbar";
import classNames from "classnames";
import { SidebarContent } from "./SidebarContent";

interface Props {
    changeMobileSidebarVisibility: () => void;
    sidebarShow: boolean;
    sidebarCollapse: boolean;
}

export const Sidebar: React.FC<Props> = ({ changeMobileSidebarVisibility, sidebarShow, sidebarCollapse }) => {
    const sidebarClass = classNames({
        sidebar: true,
        "sidebar--show": sidebarShow,
        "sidebar--collapse": sidebarCollapse,
    });

    return (
        <div className={sidebarClass}>
            <button type="button" className="sidebar__back" onClick={changeMobileSidebarVisibility} />
            <Scrollbar className="sidebar__scroll scroll">
                <div className="sidebar__wrapper sidebar__wrapper--desktop">
                    <SidebarContent onClick={() => {}} />
                </div>
                <div className="sidebar__wrapper sidebar__wrapper--mobile">
                    <SidebarContent onClick={changeMobileSidebarVisibility} />
                </div>
            </Scrollbar>
        </div>
    );
};

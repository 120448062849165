import React, { useState } from "react";
import classNames from "classnames";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { DepartmentTable } from "./DepartmentTable";

export const DepartmentTabs: React.FC = () => {
  const [activeTab, setActiveTab] = useState("1");
  const toggle = ({ tab }: { tab: any }) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="tabs__wrap">
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classNames({ active: activeTab === "1" })}
            onClick={() => toggle({ tab: "1" })}
          >
            Department
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <DepartmentTable />
        </TabPane>
      </TabContent>
    </div>
  );
};

import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toastify } from "../../../../components/Toastr";
import { Context } from "../../../../context/Context";

export const usePrintedApproved = () => {
    const { state } = useContext(Context);
    const cache = useQueryClient();
    const mutation = useMutation<AxiosResponse<any>, unknown, any, unknown>(
        (values: any) => {
            return axios.post(
                `${process.env.REACT_APP_HOST}/api/document/assigned/approvedPrinted`,
                values,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + state.token,
                    },
                }
            );
        },
        {
            onSuccess: () => {
                cache.refetchQueries("outgoing_documents");
                cache.refetchQueries("incoming_documents");
                cache.refetchQueries("approved_documents");
                cache.refetchQueries("incoming_documents_dept");
                toastify(200, "You've successfully released.");
            },
        }
    );

    return [mutation];
};

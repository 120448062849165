import React from "react";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import { lighten, makeStyles } from "@material-ui/core/styles";

import { GlobalFilter } from "./GlobalFilter";

interface Props {
    preGlobalFilteredRows?: any;
    globalFilter?: any;
    setGlobalFilter?: any;
    AddModal?: any;
}

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: 0,
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === "light"
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark,
              },
    title: {
        flex: "1 1 100%",
    },
}));

export const TableToolbar: React.FC<Props> = ({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
    AddModal,
}) => {
    const classes = useToolbarStyles();

    return (
        <Toolbar className={clsx(classes.root)}>
            <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
            />
            {AddModal ? <AddModal /> : ""}
        </Toolbar>
    );
};

import axios from "axios";
import { useContext } from "react";
import { useQuery } from "react-query";
import { Context } from "../../../context/Context";

export const fetchAdmin = async (data) => {
    const results = await axios
        .get(`${process.env.REACT_APP_HOST}/api/user/admin/all/${data.queryKey[2]}/${data.queryKey[3]}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + data.queryKey[1],
            },
        })
        .then((res) => res.data.data);

    return results;
};

export const useAdmin = () => {
    const { state } = useContext(Context);

    return useQuery(["admins", state.token, state.department_id, state.employee_id], fetchAdmin);
};

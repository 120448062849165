import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import AddIcon from "@material-ui/icons/Add";
import PrintIcon from "@material-ui/icons/Print";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import { useSaveDocuments } from "../hooks/useSaveDocuments";
import { useEmployees } from "../hooks/useEmployees";
import { useDepartmentsList } from "../hooks/useDepartmentList";
import { useCategory } from "../../../Manager/hooks/useCategory";
import { useType } from "../../../Manager/hooks/useType";
import { useAction } from "../../../Manager/hooks/useAction";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useEmployeesByDept } from "../hooks/useRetrieveUser";
import { toastify } from "../../../../components/Toastr";
import { EnchancedModal } from "../../../../components/Modal/EnhancedModal";
import { Context } from "../../../../context/Context";
import { Col, Row } from "reactstrap";
import Swal from "sweetalert2";

interface Props {
    isDept: boolean;
}

interface EmployeeInfo {
    value: string;
    label: string;
    department: string[] | undefined;
    isOffice: boolean;
}

interface EmployeeDetails {
    employee: EmployeeInfo[];
}

export const CorrespondenceModal: React.FC<Props> = () => {
    const { state } = useContext(Context);

    const { data = [], isLoading } = useEmployees();
    const { data: dataDepartment = [] } = useDepartmentsList();
    const { data: dataCategory = [], isLoading: isLoadingCategory } = useCategory();
    const { data: dataType = [], isLoading: isLoadingType } = useType();
    const { data: dataAction = [], isLoading: isLoadingAction } = useAction();
    // const { data: dataUser = [] } = useEmployeesByDept();
    // useState
    const [correspondence, setCorrespondence] = useState<any>({
        source: "",
        source_id: "",
        document_type: "",
        category: "",
        subject_matter: "",
        purpose: "",
        amount: "0",
        route: [],
        createdBy: state.employee_id,
        isDept: 0,
        isPrint: false,
    });
    const [files, setFiles] = useState<any>([]);
    const [open, setOpen] = React.useState(false);
    const [dept, setDept] = useState<any>(true);
    const [isPrint, setPrint] = useState<any>(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [create] = useSaveDocuments(setDisabled, setOpen);
    const [employeeDetails, setEmployeeDetails] = useState<EmployeeDetails>({
        employee: [],
    });
    // console.log(state);
    useEffect(() => {
        setDisabled(false);
    }, []);

    useEffect(() => {
        let employeeInfo: any = [];

        if (dept === true && isLoading === false) {
            const lists = dataDepartment?.map((row: any) => ({
                value: row.DepartmentID,
                label: row.DepartmentName,
                CampusName: row.CampusName,
                isOffice: true,
            }));
            employeeInfo = lists.sort();
        } else {
            const lists = data?.data?.map((row: any) => ({
                value: row.EmployeeID,
                label: row.EmployeeName,
                department: dataDepartment.find(
                    (rs: any) => rs.DepartmentID === (row.SubunitID ? row.SubunitID : row.DepartmentID)
                ),
                isOffice: false,
            }));

            employeeInfo = lists;
        }

        setEmployeeDetails({
            ...employeeDetails,
            employee: employeeInfo,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dept, isLoading]);

    const handlePrintOpen = () => {
        Swal.fire({
            title: "Oooopps",
            text: "This feature is unavailable for this moment.",
            icon: "warning",
        });
    };

    const handleAddRoute = () => {
        if (state.department_id_action || state.department_id) {
            setOpen((prev) => !prev);
            setCorrespondence({
                ...correspondence,
                source: state.department_name_action || state.department_name,
                source_id: state.department_id_action || state.department_id,
            });
        } else {
            Swal.fire({
                title: "Oooopps",
                text: "Please select your department.",
                icon: "warning",
            });
        }
    };

    const handleClose = () => {
        setOpen(false);
        setDept(true);
        setFiles("");
        setPrint(false);
    };

    const handleChange =
        (name: any) =>
        ({ target: { value } }) => {
            setCorrespondence({ ...correspondence, [name]: value });
        };

    const handleSelectRoute = (event: any) => {
        let route = [];
        // eslint-disable-next-line array-callback-return
        event.map((row: any) => {
            let status = "Not yet acknowledged";
            if (route.length > 0) {
                status = "Pending";
            }

            route.push({
                label: row.isOffice ? null : row.label,
                value: row.isOffice ? null : row.value,
                department: row.value,
                department_name: row.label,
                status: status,
            });
        });

        setCorrespondence({ ...correspondence, route: route });
    };

    const handleFile = (event: any) => {
        setFiles(event.target.files);
        setPrint(false);
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleCheckDepartment = (event: any) => {
        setDept(event.target.checked);
    };

    const handlePrint = (event: any) => {
        setPrint(event.target.checked);
        setCorrespondence({ ...correspondence, isPrint: event.target.checked });
        setFiles("");
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const lists = [...files];

        if (correspondence.route.length === 0) {
            return toastify(199, "Route's is required.");
        } else if (!correspondence.document_type) {
            return toastify(199, "Document type is required.");
        } else if (!correspondence.category) {
            return toastify(199, "Category is required.");
        } else if (!correspondence.purpose) {
            return toastify(199, "Purpose/Action is required.");
        } else if (!correspondence.subject_matter) {
            return toastify(199, "Subject matter is required.");
        } else if (!lists.length && !isPrint) {
            return toastify(199, "Upload files is required.");
        } else {
            setDisabled(true);
            const formData = new FormData();
            const files = lists;

            formData.append("data", JSON.stringify(correspondence));
            for (let i = 0; i <= files.length; i++) {
                formData.append("files", files[i]);
            }

            create.mutate(formData);
            setCorrespondence({
                ...correspondence,
                document_type: "",
                category: "",
                subject_matter: "",
                purpose: "",
                amount: "0",
                route: [],
                isPrint: false,
            });

            setFiles("");
            setPrint(false);
        }
    };

    const customStyles = {
        container: (styles: any) => ({
            ...styles,
            marginTop: 20,
        }),
    };

    const customStylesHeader = {
        container: (styles: any) => ({
            ...styles,
            marginTop: 20,
        }),
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const customStylesDepartment = {
        container: (styles: any) => ({
            ...styles,
            marginTop: 20,
        }),
    };

    const ListItem = () => {
        const lists = [...files];
        return (
            <ol>
                {lists.map((item: any, index: number) => (
                    <li key={index} style={{ padding: 5 }}>
                        {item.name}
                    </li>
                ))}
            </ol>
        );
    };
    return (
        <div>
            <Tooltip title="Add">
                <IconButton
                    aria-label="add"
                    // onClick={() => {
                    //     setOpen((prev) => !prev);
                    // }}
                    onClick={handleAddRoute}
                >
                    <AddIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Print">
                <IconButton aria-label="print" onClick={handlePrintOpen}>
                    <PrintIcon />
                </IconButton>
            </Tooltip>
            <EnchancedModal
                open={open}
                setOpen={setOpen}
                title={"Add Correspondence"}
                subtitle={"Fill up correspondence details."}
                view={false}
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                overflow={"auto"}
                position={""}
                display={"block"}
                disabled={disabled}
            >
                <Row>
                    <Col lg="12">
                        {/* <FormHelperText>Check if route is by office.</FormHelperText> */}
                        {/* <span>
                            Check if route is by office
                            <Checkbox
                                style={{ float: "inline-start" }}
                                inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                                checked={dept}
                                onChange={handleCheckDepartment}
                            />
                        </span> */}
                        {/* <Select
                            styles={customStylesDepartment}
                            className="basic-single"
                            classNamePrefix="select"
                            name="department"
                            // options={state.list_department}
                            options={state.list_department.map((row) => ({
                                value: row.department_id,
                                label: row.department_name,
                            }))}
                            placeholder={"Select your department"}
                            getOptionLabel={(option: any) => {
                                return `${option.department_name}_${option.campus_name}`;
                            }}
                            onChange={(event) => {
                                setCorrespondence({
                                    ...correspondence,
                                    source: event ? event.department_name : null,
                                    source_id: event ? event.department_id : null,
                                });
                            }}
                            isClearable={true}
                        /> */}

                        {!isLoading ? (
                            <Select
                                styles={customStylesHeader}
                                isMulti
                                name="employees"
                                options={employeeDetails.employee}
                                getOptionLabel={(option: any) => {
                                    return dept
                                        ? `${option.label}_${option.CampusName}`
                                        : `${option.label} - ${option.department?.DepartmentName}_${option.department?.CampusName}`;
                                }}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder={"Select Route's"}
                                onChange={handleSelectRoute}
                            />
                        ) : (
                            <Select
                                styles={customStyles}
                                isMulti
                                name="colors"
                                className="basic-multi-select"
                                classNamePrefix="select"
                                placeholder={"Fetching Route..."}
                                onChange={handleSelectRoute}
                            />
                        )}
                    </Col>
                </Row>
                {!isLoadingType ? (
                    <Select
                        styles={customStyles}
                        className="basic-single"
                        classNamePrefix="select"
                        name="type"
                        options={dataType.map((row) => ({
                            value: row.id,
                            label: row.name,
                        }))}
                        placeholder={"Select Document Type"}
                        onChange={(event) => {
                            setCorrespondence({
                                ...correspondence,
                                document_type: event ? event.label : null,
                            });
                        }}
                        isClearable={true}
                    />
                ) : (
                    <Select
                        styles={customStyles}
                        className="basic-single"
                        classNamePrefix="select"
                        name="type"
                        placeholder={"Fetching Document Type..."}
                    />
                )}
                {!isLoadingCategory ? (
                    <Select
                        styles={customStyles}
                        className="basic-single"
                        classNamePrefix="select"
                        name="type"
                        options={dataCategory.map((row) => ({
                            value: row.id,
                            label: row.name,
                        }))}
                        placeholder={"Select Category"}
                        onChange={(event) => {
                            setCorrespondence({
                                ...correspondence,
                                category: event ? event.label : null,
                            });
                        }}
                        isClearable={true}
                    />
                ) : (
                    <Select
                        styles={customStyles}
                        className="basic-single"
                        classNamePrefix="select"
                        name="type"
                        placeholder={"Fetching Category..."}
                    />
                )}

                {!isLoadingAction ? (
                    <Select
                        styles={customStyles}
                        className="basic-single"
                        classNamePrefix="select"
                        name="purpose"
                        options={dataAction.map((row) => ({
                            value: row.id,
                            label: row.name,
                        }))}
                        placeholder={"Select Purpose/Action Required"}
                        onChange={(event) => {
                            setCorrespondence({
                                ...correspondence,
                                purpose: event ? event.label : null,
                            });
                        }}
                        isClearable={true}
                    />
                ) : (
                    <Select
                        styles={customStyles}
                        className="basic-single"
                        classNamePrefix="select"
                        name="action"
                        placeholder={"Fetching Purpose/Action Required..."}
                    />
                )}

                <TextField
                    margin="dense"
                    label="Subject Matter"
                    type="Subject_Matter"
                    fullWidth
                    value={correspondence.subject_matter}
                    onChange={handleChange("subject_matter")}
                />
                <TextField
                    margin="dense"
                    label="Amount"
                    type="number"
                    fullWidth
                    value={correspondence.amount}
                    onChange={handleChange("amount")}
                />
                <span style={{ color: "gray" }}>
                    Printed File?
                    <Checkbox
                        style={{ float: "inline-start" }}
                        value={correspondence.isPrint}
                        inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                        checked={isPrint}
                        onChange={handlePrint}
                    />
                </span>
                <Button variant="contained" disabled={isPrint} component="label" style={{ marginTop: 10 }}>
                    Upload File
                    <input
                        accept=".doc,.docx,application/pdf,application/msword,
                            application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        type="file"
                        hidden
                        onChange={handleFile}
                        multiple
                    />
                </Button>

                <InputLabel style={{ marginTop: 10, color: "red" }}>
                    {files.length > 0 ? <ListItem /> : "No file is attached."}
                </InputLabel>
            </EnchancedModal>
        </div>
    );
};

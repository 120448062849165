import React, { useContext, useState } from "react";
import classNames from "classnames";
import { Nav, NavItem, NavLink, TabContent, TabPane, Row } from "reactstrap";
import { makeStyles } from "@material-ui/core/styles";
import { IncomingTable } from "./IncomingTable";
import { IncomingTableByDept } from "./IncomingTableByDept";
import { Context } from "../../../../context/Context";
import Checkbox from "@material-ui/core/Checkbox";
import { TextField } from "@material-ui/core";
const moment = require("moment");

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));
export const IncomingTabs: React.FC = () => {
    const { state } = useContext(Context);
    const [activeTab, setActiveTab] = useState("1");
    const toggle = ({ tab }: { tab: any }) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    const classes = useStyles();
    const current = moment(new Date()).format("DD/MM/YYYY").toString();

    const [fromDate, setFromDate] = useState<string>(null);
    const [toDate, setToDate] = useState<string>(null);
    const [isFilter, setFilter] = useState<boolean>(false);

    const handleIsFilter = (event) => {
        setFilter(event.target.checked);
        setFromDate(null);
        setToDate(null);
    };

    return (
        <div className="tabs__wrap">
            <span>Filter incoming document date range</span>
            <Checkbox
                inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                checked={isFilter}
                onChange={handleIsFilter}
            />
            {isFilter ? (
                <TextField
                    id="dateFrom"
                    size="small"
                    label="Date from"
                    type="date"
                    defaultValue={current}
                    onChange={(event) => {
                        setFromDate(event.target.value);
                    }}
                    // className={classes.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            ) : (
                ""
            )}

            {isFilter ? (
                <TextField
                    id="dateTo"
                    size="small"
                    label="Date to"
                    type="date"
                    defaultValue={toDate}
                    className={classes.textField}
                    onChange={(event) => {
                        setToDate(event.target.value);
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            ) : (
                ""
            )}

            <Nav tabs>
                <NavItem>
                    <NavLink className={classNames({ active: activeTab === "1" })} onClick={() => toggle({ tab: "1" })}>
                        <Row>
                            UNIWIDE
                            <span
                                className="ml-1"
                                style={{
                                    fontSize: "0.7rem",
                                    fontWeight: 700,
                                    marginTop: -5,
                                    color: "red",
                                }}
                            >
                                {state.count_incoming}
                            </span>
                        </Row>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classNames({ active: activeTab === "2" })} onClick={() => toggle({ tab: "2" })}>
                        <Row>
                            INTERNAL(by office)
                            <span
                                className="ml-1"
                                style={{
                                    fontSize: "0.7rem",
                                    fontWeight: 700,
                                    marginTop: -5,
                                    color: "red",
                                }}
                            >
                                {state.count_incoming_dept}
                            </span>
                        </Row>
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <IncomingTable fromDate={fromDate} toDate={toDate} isFilter={isFilter} />
                </TabPane>
                <TabPane tabId="2">
                    <IncomingTableByDept fromDate={fromDate} toDate={toDate} isFilter={isFilter} />
                </TabPane>
            </TabContent>
        </div>
    );
};

import axios from "axios";
import { useContext } from "react";
import { useQuery } from "react-query";
import { Context } from "../../../../context/Context";

export const fetchReleased = async (data) => {
  const results = await axios
    .get(`${process.env.REACT_APP_HOST}/api/release/outgoing`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + data.queryKey[2],
      },
    })
    .then((res) => res.data.data);

  return results;
};

export const useReleaseTrail = () => {
  const { state } = useContext(Context);

  return useQuery(
    ["audit_released", state.employee_id, state.token],
    fetchReleased,
    {
      initialData: () => [],
    }
  );
};

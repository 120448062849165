import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { NavLink } from 'react-router-dom';

const SidebarLink = ({
  title, icon, newLink, route, onClick,count,count_return,count_pending
}) => (
  <NavLink
    to={route}
    onClick={onClick}
    activeClassName="sidebar__link-active"
  >
    <li className="sidebar__link">
      {icon ? <span className={`sidebar__link-icon lnr lnr-${icon}`} /> : ''}
      <p className="sidebar__link-title">
        {title}
        <span className="ml-1" style={{fontSize: "0.7rem", fontWeight: 700, marginTop: -5, color: "red"}}>{count}</span>
        { title ==="Outgoing" ? <span className="ml-1" style={{fontSize: "0.6rem", fontWeight: 700, marginTop: -5, color: "blue"}} id="pending">[{count_return},{count_pending}]</span> :""}
        {newLink ? <Badge className="sidebar__link-badge"><span>New</span></Badge> : ''}
      </p>
    </li>
  </NavLink>
);

SidebarLink.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  newLink: PropTypes.bool,
  route: PropTypes.string,
  onClick: PropTypes.func,
  count : PropTypes.any,
  count_return: PropTypes.any,
  count_pending: PropTypes.any
};

SidebarLink.defaultProps = {
  icon: '',
  newLink: false,
  route: '/',
  onClick: () => {},
};

export default SidebarLink;

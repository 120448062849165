import React from "react";

// import Button from "@material-ui/core/Button";
import { Button, Spinner } from "reactstrap";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

interface Props {
  open: boolean;
  setOpen: any;
  title: string;
  subtitle: string;
  view: boolean;
  handleClose?: () => void;
  handleSubmit?: any;
  width?: number;
  height?: number | string;
  overflow?: string;
  position?: any;
  display?: string;
  disabled?: boolean;
}

export const EnchancedModal: React.FC<Props> = ({
  open,
  title,
  subtitle,
  children,
  view,
  handleClose,
  handleSubmit,
  width,
  height,
  overflow,
  position,
  display,
  disabled = false,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent
        style={{
          width: width,
          height: height,
          overflow: overflow,
          position: position,
          display: display,
        }}
      >
        <DialogContentText>{subtitle}</DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        {view ? (
          <Button onClick={handleClose} color="danger" outline>
            Close
          </Button>
        ) : (
          <>
            <Button
              onClick={handleClose}
              disabled={disabled}
              color="danger"
              outline
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              color="primary"
              disabled={disabled}
              outline
            >
              {disabled === true && (
                <Spinner color="primary" size="sm" className="mr-1" />
              )}
              Save
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

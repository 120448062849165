import React, { useContext } from "react";
import { Route, Switch } from "react-router-dom";
import Layout from "../Layout/index";
import { MainWrapper } from "../App/MainWrapper";

import { LogIn } from "../LogIn/index";

import { CorrespondenceOutgoing } from "../Correspondence/Outgoing/index";
import { CorrespondenceIncoming } from "../Correspondence/Incoming/index";
import { CorrespondenceApproved } from "../Correspondence/Approved/index";
import { CorrespondenceArchives } from "../Correspondence/Archives/index";
import { CorrespondenceReports } from "../Correspondence/Reports/index";
import { CorrespondenceIncomingReports } from "../Correspondence/ReportIncoming/index";
import { Users } from "../Users/index";
import { Admin } from "../Admin/index";
import { Manager } from "../Manager/index";
import { AuthRoute } from "./AuthRoute";
import { Department } from "../Department/index";
import { Context } from "../../context/Context";

const AdminPanel = () => (
    <Switch>
        <Route path="/admin/user" component={Users} />
        <Route path="/admin/manager" component={Manager} />
        <Route path="/superadmin/admin" component={Admin} />
        <Route path="/superadmin/manager" component={Manager} />
        <Route path="/superadmin/department" component={Department} />
    </Switch>
);

const Pages = () => (
    <Switch>
        <Route path="/correspondence/outgoing" component={CorrespondenceOutgoing} />
        <Route path="/correspondence/incoming" component={CorrespondenceIncoming} />
        <Route path="/correspondence/approved" component={CorrespondenceApproved} />
        <Route path="/correspondence/archives" component={CorrespondenceArchives} />
        <Route path="/correspondence/reports" component={CorrespondenceReports} />
        <Route path="/correspondence/incomingreports" component={CorrespondenceIncomingReports} />
    </Switch>
);

const wrappedRoutes = () => (
    <div>
        <Layout />
        <div className="container__wrap">
            <Route path="/" component={Pages} />
            <Route path="/admin" component={AdminPanel} />
            <Route path="/superadmin" component={AdminPanel} />
        </div>
    </div>
);

export const Routes: React.FC = () => {
    const { state } = useContext(Context);
    let auth = state.token.length > 0 ? true : false;
    return (
        <MainWrapper>
            <main>
                <Switch>
                    <Route exact path="/" component={LogIn} />
                    <Route exact path="/log_in" component={LogIn} />
                    <AuthRoute Component={wrappedRoutes} path="/admin" isAuth={auth} />
                    <AuthRoute Component={wrappedRoutes} path="/superadmin" isAuth={auth} />
                    <AuthRoute Component={wrappedRoutes} path="/correspondence" isAuth={auth} />
                </Switch>
            </main>
        </MainWrapper>
    );
};

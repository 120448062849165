import axios from "axios";
import { useContext } from "react";
import { useQuery } from "react-query";
import { Context } from "../../../../context/Context";

export const fetchAllEmployees = async (data) => {
    const results = await axios
        .get(`${process.env.REACT_APP_HOST}/api/department/user`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + data.queryKey[1],
            },
            params: {
                dept_id: data.queryKey[2].deptId,
                dept_name: data.queryKey[2].deptName,
            },
        })
        .then((res) => res.data);

    return results;
};

export const useAllEmployees = () => {
    const { state } = useContext(Context);
    const data = {
        deptId: state.department_id_action || state.department_id,
        deptName: state.department_name_action || state.department_name,
    };
    return useQuery(["all_employees", state.token, data], fetchAllEmployees);
};

import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toastify } from "../../../components/Toastr";
import { Context } from "../../../context/Context";

export const useDelete = () => {
  const { state } = useContext(Context);
  const cache = useQueryClient();
  const mutation = useMutation<AxiosResponse<any>, unknown, any, unknown>(
    (values: any) => {
      return axios.post(
        `${process.env.REACT_APP_HOST}/api/user/user/delete`,
        values,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + state.token,
          },
        }
      );
    },
    {
      onSuccess: () => {
        cache.refetchQueries("user");
        cache.refetchQueries("admin");
        toastify(200, "You've successfully delete the user.");
      },
    }
  );

  return [mutation];
};

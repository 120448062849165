import React, { useContext, useEffect } from "react";
import moment from "moment";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import { useCancelledSeen } from "../hooks/useCancelledSeen";
import { EnhancedTable } from "../../../../components/Table/EnhancedTable";
import { useArchivesDocuments } from "../hooks/useArchivesDocuments";
import { useFiles } from "../hooks/useRetrieveFiles";
import { Context } from "../../../../context/Context";
import Tooltip from "@material-ui/core/Tooltip";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import Swal from "sweetalert2";
interface Props {
    toDate?: string;
    fromDate?: string;
    isFilter?: boolean;
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(1),
            width: 10,
            height: 10,
        },
        extendedIcon: {
            marginRight: theme.spacing(1),
        },
        root: {
            width: "100%",
        },
        paper: {
            width: "100%",
            marginBottom: theme.spacing(2),
        },
    })
);

export const ArchivesTable: React.FC<Props> = ({ fromDate, toDate, isFilter }) => {
    const classes = useStyles();
    const { dispatch, state } = useContext(Context);
    const { data = [], isLoading } = useArchivesDocuments({
        fromDate,
        toDate,
        isFilter,
    });
    const {
        data: { data: dataFiles = [] },
    }: any = useFiles();
    const [seen] = useCancelledSeen();
    useEffect(() => {
        if (isLoading === false) {
            dispatch({
                type: "COUNT_ARCHIVED_ACTION",
                count_archived: data.length,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const columns: any = [
        {
            Header: "Reference No",
            accessor: "reference_no",
            width: 130,
        },
        {
            Header: "Source Office",
            accessor: "source",
            width: 130,
        },
        {
            Header: "Type",
            accessor: "document_type",
            width: 130,
        },
        {
            Header: "Category",
            accessor: "category",
            width: 150,
        },
        {
            Header: "Purpose/Action Required",
            accessor: "purpose",
            width: 150,
        },
        {
            Header: "Subject Matter",
            accessor: "subject_matter",
            width: 150,
        },

        {
            Header: "Document Date",
            accessor: "createdAt",
            Cell: (row: any) => <>{moment(row.cell.value).format("MM/DD/YYYY hh:mm a")}</>,
            width: 120,
        },
        {
            Header: "Received By",
            accessor: "received_by",
            Cell: (row: any) => (
                <>{row.cell.row.original.list_route?.name ? row.cell.row.original.list_route.name : ""}</>
            ),
        },
        {
            Header: "File",
            accessor: "attach_name",
            Cell: (row: any) => {
                const filter = dataFiles.filter((data) => data.document_id === row.cell.row.original.id);
                return (
                    <div>
                        {filter.map((item: any, index: number) => {
                            return (
                                <p key={index} style={{ color: "red" }}>
                                    {item.attach_name ? item.attach_name : "No File"}
                                </p>
                            );
                        })}
                    </div>
                );
            },
        },
        {
            Header: "Status",
            accessor: "status",
            width: 100,
            Cell: (row: any) => (
                <>
                    <p style={{ color: "red" }}>
                        {row.cell.row.original.list_route?.status ? row.cell.row.original.list_route.status : ""}
                    </p>
                </>
            ),
        },
        {
            headerClassName: "correspondence-action",
            Header: "Action",
            accessor: "action",
            Cell: (row: any) => (
                <>
                    <Tooltip title="Seen">
                        <IconButton
                            ria-label="approved"
                            className={classes.margin}
                            onClick={() => {
                                handleSeenFiles(row.cell.row.original.id);
                            }}
                        >
                            <DoneAllIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </>
            ),
            width: 150,
        },
    ];

    const handleSeenFiles = (id: string) => {
        Swal.fire({
            title: "Information!",
            text: "Are you sure you want to seen it.?",
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, approved it!",
            customClass: {
                container: "view_modal",
            },
        }).then(async (result) => {
            if (result.isConfirmed) {
                seen.mutate({ document_id: id, createdBy: state.employee_id });
                Swal.fire("Seen!", "Your document has been seen.", "success");
            }
        });
    };

    if (isLoading) {
        return <span>Loading...</span>;
    }

    return (
        <div className={classes.root}>
            <EnhancedTable columns={columns} data={data} view={true} />
        </div>
    );
};

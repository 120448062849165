/* eslint-disable no-useless-concat */
import React, { useState, useContext, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { EnchancedModal } from "../../../../components/Modal/EnhancedModal";
import { toastify } from "../../../../components/Toastr";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import { useSubmitReturn } from "../hooks/useSubmitReturn";
import { Context } from "../../../../context/Context";
interface Props {
    id: any;
    openSubmit: boolean;
    setOpenReSubmit: any;
    isPrint: boolean;
}

export const ResubmitModal: React.FC<Props> = ({ id, openSubmit, setOpenReSubmit, isPrint }) => {
    const [create] = useSubmitReturn();
    const { state } = useContext(Context);
    const [details, setDetails] = useState({
        id: "",
        employee_id: state.employee_id,
        remarks: "",
    });

    useEffect(() => {
        if (id !== null) {
            setDetails({
                ...details,
                id: id,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const [files, setFiles] = useState<any>([]);
    const handleChange =
        (name) =>
        ({ target: { value } }) => {
            setDetails({ ...details, [name]: value });
        };
    const handleClose = () => {
        setOpenReSubmit(false);
        setFiles("");
        details.remarks = "";
    };
    const handleSubmit = (event: any) => {
        event.preventDefault();

        const lists = [...files];
        if (!lists.length && !isPrint) {
            return toastify(199, "File is required.");
        }

        if (!details.remarks.trim()) {
            return toastify(199, "Remarks is required.");
        }

        const formData = new FormData();
        formData.append("data", JSON.stringify(details));
        for (let i = 0; i <= files.length; i++) {
            formData.append("files", files[i]);
        }

        create.mutate(formData);

        setFiles("");
        setOpenReSubmit(false);
    };
    const handleFile = (event) => {
        setFiles(event.target.files);
    };

    const ListItem = () => {
        const lists = [...files];
        return (
            <ol>
                {lists.map((item: any, index: number) => (
                    <li key={index} style={{ padding: 5 }}>
                        {item.name}
                    </li>
                ))}
            </ol>
        );
    };

    return (
        <div>
            <EnchancedModal
                open={openSubmit}
                setOpen={setOpenReSubmit}
                title={"Documents"}
                subtitle={"Attach File"}
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                view={false}
                width={600}
                height={"auto"}
                overflow={"hidden"}
                position={""}
                display={"block"}
            >
                <Button variant="contained" disabled={isPrint} component="label" style={{ marginTop: 10 }}>
                    Upload File
                    <input
                        accept=".doc,.docx,application/pdf,application/msword,
                            application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        type="file"
                        hidden
                        onChange={handleFile}
                        multiple
                    />
                </Button>

                {!isPrint ? (
                    <InputLabel style={{ marginTop: 10, color: "red" }}>
                        {files.length > 0 ? <ListItem /> : "No file is attached."}
                    </InputLabel>
                ) : (
                    <InputLabel style={{ marginTop: 10, color: "red" }}>
                        {"No uploaded file!\n" + "Please check hard copy."}
                    </InputLabel>
                )}

                <TextField
                    multiline
                    rows={4}
                    rowsMax={"auto"}
                    margin="dense"
                    label="Remarks"
                    type="remarks"
                    fullWidth
                    value={details.remarks}
                    onChange={handleChange("remarks")}
                    variant="outlined"
                    style={{ top: "10px", left: "13px", width: "450px" }}
                />
            </EnchancedModal>
        </div>
    );
};

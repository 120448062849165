import axios from "axios";
import { useContext } from "react";
import { useQuery } from "react-query";
import { Context } from "../../../../context/Context";

export const fetchAuditIncomingDept = async (data) => {
    const results = await axios
        .get(`${process.env.REACT_APP_HOST}/api/dept/audit/incoming/`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + data.queryKey[2],
            },
            params: {
                from: data.queryKey[3].fromDate,
                to: data.queryKey[3].toDate,
                subDepartment: data.queryKey[3].subDepartment,
                employee_id: data.queryKey[3].employee_id,
                toFilter: data.queryKey[3].toFilter,
            },
        })
        .then((res) => res.data.data);

    return results;
};

export const useAuditTrailIncomingDept = (data) => {
    const { state } = useContext(Context);

    return useQuery(["audit_documents_incoming_dept", state.employee_id, state.token, data], fetchAuditIncomingDept);
};

import React from "react";

import { EnchancedModal } from "../../../../components/Modal/EnhancedModal";
import { EnhancedTable } from "../../../../components/Table/EnhancedTable";
import { useDepartments } from "../hooks/useDepartments";
import { useDocument } from "../hooks/useDocument";
import { useOldDepartments } from "../hooks/useOldDepartments";
interface Props {
    open: boolean;
    setOpen: any;
    id: string;
}

export const ViewModal: React.FC<Props> = ({ open, setOpen, id }) => {
    const { data, isLoading } = useDocument(id);
    const { data: departments } = useDepartments();
    const { data: old_Departments } = useOldDepartments();
    const columns: any = React.useMemo(
        () => [
            {
                Header: "Name",
                accessor: "name",
                width: 190,
                Cell: (row: any) => {
                    const is_additional = row.cell.row.original.is_additional;
                    return is_additional ? (
                        <p style={{ color: "#d65238" }}>{row.cell.row.original.name}</p>
                    ) : (
                        <p>{row.cell.row.original.name}</p>
                    );
                },
            },
            {
                Header: "Department",
                accessor: "department",
                width: 150,
                Cell: (row: any) => {
                    const department = departments?.find(
                        (results: any) => results.DepartmentID === Number(row.cell.row.original.department)
                    );

                    const oldDepartment = old_Departments?.find(
                        (old_results: any) => old_results.DepartmentID === Number(row.cell.row.original.department)
                    );

                    const is_additional = row.cell.row.original.is_additional;
                    const departmentName = row.cell.row.original.is_old ? oldDepartment : department;
                    return is_additional ? (
                        <p style={{ color: "#d65238" }}>
                            {row.cell.row.original.department_name
                                ? row.cell.row.original.department_name
                                : departmentName?.DepartmentName
                                ? departmentName.DepartmentName
                                : "Loading"}
                        </p>
                    ) : (
                        <p>
                            {row.cell.row.original.department_name
                                ? row.cell.row.original.department_name
                                : departmentName?.DepartmentName
                                ? departmentName.DepartmentName
                                : "Loading"}
                        </p>
                    );
                },
            },
            {
                Header: "Remarks",
                accessor: "remarks",
                width: 100,
                Cell: (row: any) => {
                    const is_additional = row.cell.row.original.is_additional;
                    return is_additional ? (
                        <p style={{ color: "#d65238" }}>{row.cell.row.original.remarks}</p>
                    ) : (
                        <p title={"Final Remarks : " + row.cell.row.original.final_remarks}>
                            {row.cell.row.original.remarks}
                        </p>
                    );
                },
            },
            {
                Header: "Status",
                accessor: "status",
                width: 100,
                Cell: (row: any) => {
                    const is_additional = row.cell.row.original.is_additional;
                    return is_additional ? (
                        <p style={{ color: "#d65238" }}>{row.cell.row.original.status}</p>
                    ) : (
                        <p>{row.cell.row.original.status}</p>
                    );
                },
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleClose = () => {
        setOpen(false);
    };

    if (isLoading) {
        return <span>Loading...</span>;
    }

    return (
        <div>
            <EnchancedModal
                open={open}
                setOpen={setOpen}
                title={"Audit Trail"}
                subtitle={"List of Routes"}
                view={true}
                handleClose={handleClose}
            >
                <EnhancedTable columns={columns} data={data} view={false} />
            </EnchancedModal>
        </div>
    );
};

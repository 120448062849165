import axios from "axios";
import { useContext } from "react";
import { useQuery } from "react-query";
import { Context } from "../../../../context/Context";

export const fetchAssignedDepartmentUser = async (data) => {
  const results = await axios
    .get(
      `${process.env.REACT_APP_HOST}/api/department/assign/user/${data.queryKey[2]}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + data.queryKey[1],
        },
      }
    )
    .then((res) => res.data.data);

  return results;
};

export const useFetchAssignedDepartment = () => {
  const { state } = useContext(Context);

  return useQuery(
    ["manage_department_assign", state.token, state.employee_id],
    fetchAssignedDepartmentUser
  );
};

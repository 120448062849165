/* eslint-disable no-useless-concat */
import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { EnchancedModal } from "../../../../components/Modal/EnhancedModal";
import { toastify } from "../../../../components/Toastr";
import { useSaveReturn } from "../hooks/useSaveReturn";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";

interface Props {
    id: any;
    returnOpen: boolean;
    setReturnOpen: any;
    isPrint: boolean;
}

export const IncomingReturnModal: React.FC<Props> = ({ id, returnOpen, setReturnOpen, isPrint }) => {
    const [create] = useSaveReturn();
    const [details, setDetails] = useState({
        id: "",
        remarks: "",
    });

    useEffect(() => {
        if (id !== null) {
            setDetails({
                ...details,
                id: id,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const info = JSON.parse(localStorage.getItem("state"));
    const [files, setFiles] = useState<any>("");
    const handleChange =
        (name: string) =>
        ({ target: { value } }) => {
            setDetails({ ...details, [name]: value });
        };

    const handleClose = () => {
        setReturnOpen(false);
        setFiles("");
        setDetails({
            ...details,
            remarks: "",
        });
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (!details.remarks) {
            return toastify(199, "Remarks is required.");
        }
        if (info.employee_id) {
            details["employee_id"] = info.employee_id;
        }
        const formData = new FormData();
        formData.append("data", JSON.stringify(details));
        for (let i = 0; i <= files.length; i++) {
            formData.append("files", files[i]);
        }

        create.mutate(formData);

        setDetails({
            ...details,
            id: "",
            remarks: "",
        });
        setFiles("");

        setReturnOpen(false);
    };

    const handleFile = (event) => {
        setFiles(event.target.files);
    };

    const ListItem = () => {
        const lists = [...files];
        return (
            <ol>
                {lists.map((item: any, index: number) => (
                    <li key={index} style={{ padding: 5 }}>
                        {item.name}
                    </li>
                ))}
            </ol>
        );
    };

    return (
        <div>
            <EnchancedModal
                open={returnOpen}
                setOpen={setReturnOpen}
                title={"Return Documents"}
                subtitle={"Fill up details"}
                view={false}
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                width={600}
                height={"auto"}
                overflow={"hidden"}
                position={""}
                display={"block"}
            >
                <Button variant="contained" disabled={isPrint} component="label" style={{ marginTop: 10 }}>
                    Upload File
                    <input
                        accept=".doc,.docx,application/pdf,application/msword,
                            application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        type="file"
                        hidden
                        onChange={handleFile}
                        multiple
                    />
                </Button>

                {!isPrint ? (
                    <InputLabel style={{ marginTop: 10, color: "red" }}>
                        {files.length > 0 ? <ListItem /> : "No file is attached."}
                    </InputLabel>
                ) : (
                    <InputLabel style={{ marginTop: 10, color: "red" }}>
                        {"No uploaded file!\n" + "Please check hard copy."}
                    </InputLabel>
                )}

                <TextField
                    multiline
                    rows={4}
                    rowsMax={"auto"}
                    margin="dense"
                    label="Remarks"
                    type="remarks"
                    variant="outlined"
                    value={details.remarks}
                    onChange={handleChange("remarks")}
                    style={{ top: "10px", left: "13px", width: "450px" }}
                />
            </EnchancedModal>
        </div>
    );
};

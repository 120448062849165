import axios from "axios";
import { useContext } from "react";
import { useQuery } from "react-query";
import { Context } from "../../../../context/Context";

export const fetchReturn = async (data) => {
  const results = await axios
    .get(`${process.env.REACT_APP_HOST}/api/return/outgoing`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + data.queryKey[2],
      },
    })
    .then((res) => res.data.data);

  return results;
};

export const useReturnTrail = () => {
  const { state } = useContext(Context);

  return useQuery(
    ["audit_return", state.employee_id, state.token],
    fetchReturn,
    {
      initialData: () => [],
    }
  );
};

import React from "react";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";

import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { EnhancedTable } from "../../../components/Table/EnhancedTable";
import { DepartmentModal } from "./DepartmentModal";
import { useFetchAssignedDepartment } from "../hooks/useDepartment";
import { useDepartments } from "../hooks/useDepartments";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(1),
            width: 10,
            height: 10,
        },
        extendedIcon: {
            marginRight: theme.spacing(1),
        },
        root: {
            width: "100%",
        },
        paper: {
            width: "100%",
            marginBottom: theme.spacing(2),
        },
    })
);

export const DepartmentTable: React.FC = () => {
    const classes = useStyles();
    const { data = [], isLoading } = useFetchAssignedDepartment();

    const { data: dataDepartment, isLoading: isLoadingDepartment } = useDepartments();

    const columns: any = [
        {
            Header: "Email",
            accessor: "email",
            width: 100,
        },
        {
            Header: "Name ",
            accessor: "full_name",
            width: 100,
        },
        {
            Header: "Assign Departments",
            accessor: "assign_department_id",
            Cell: (row: any) => {
                const data = !isLoadingDepartment
                    ? dataDepartment.find((rs: any) => rs.DepartmentID === row.cell.row.original.assign_department_id)
                    : "";
                return <>{data.DepartmentName + "- " + data.CampusName}</>;
            },
            width: 100,
        },
        {
            headerClassName: "manager-type-action",
            Header: "Action",
            accessor: "action",
            Cell: (row: any) => (
                <>
                    <Tooltip title="Delete">
                        <IconButton
                            ria-label="delete"
                            className={classes.margin}
                            onClick={() => {
                                handleDelete(row.cell.row.original.id);
                            }}
                        >
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </>
            ),
            width: 100,
        },
    ];

    const handleDelete = (id) => {};

    if (isLoading) {
        return <span>Loading...</span>;
    }

    return (
        <div className={classes.root}>
            <EnhancedTable AddModal={DepartmentModal} columns={columns} data={data} view={true} />
        </div>
    );
};

import React, { useContext, useState, useEffect } from "react";
import classNames from "classnames";
import { Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import Select from "react-select";
import { CorrespondenceTable } from "./OutgoingTable";
import { CorrespondenceDeptTable } from "./OutgoingDeptTable";
import { Context } from "../../../../context/Context";

export const CorrespondenceTabs: React.FC = () => {
    const [activeTab, setActiveTab] = useState("1");
    const { state, dispatch } = useContext(Context);
    const toggle = ({ tab }: { tab: any }) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    const [deptId, setDeptId] = useState<Number>(0);
    const [isSubunit, setSubunit] = useState<Number>(0);
    const [deptName, setDeptName] = useState<String>("");
    const handleDepartment = (event: any) => {
        setDeptName(event?.label);
        setDeptId(Number(event?.department_id));
        setSubunit(Number(event?.isSubUnit));
    };
    // console.log(state);
    useEffect(() => {
        dispatch({
            type: "OUTGOING_DEPARTMENT_ACTION",
            department_name_action: deptName,
            department_id_action: deptId,
            department_subunit_action: isSubunit,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deptId]);

    return (
        <div className="tabs__wrap">
            <Select
                className="basic-single"
                classNamePrefix="select"
                name="department"
                placeholder={"Select your department"}
                options={state.list_department.map((row, key) => ({
                    value: key,
                    label: row.department_name,
                    campus_name: row.campus_name || "Obrero",
                    isSubUnit: row.isSubUnit,
                    department_id: row.department_id,
                }))}
                getOptionLabel={(option: any) => {
                    return `${option.label}_${option.campus_name}`;
                }}
                isClearable={false}
                defaultValue={{
                    label: state.department_name,
                    department_id: state.department_id,
                    campus_name: state.campus,
                    isSubUnit: state.isSubUnit,
                }}
                onChange={(event) => {
                    handleDepartment(event);
                }}
            />
            <Nav tabs>
                <NavItem>
                    <NavLink className={classNames({ active: activeTab === "1" })} onClick={() => toggle({ tab: "1" })}>
                        <Row>
                            UNIWIDE
                            <span
                                className="ml-1"
                                style={{
                                    fontSize: "0.7rem",
                                    fontWeight: 700,
                                    marginTop: -5,
                                    color: "red",
                                }}
                            >
                                {state.count_outgoing}
                            </span>
                        </Row>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={classNames({ active: activeTab === "2" })} onClick={() => toggle({ tab: "2" })}>
                        <Row>
                            INTERNAL(by office)
                            <span
                                className="ml-1"
                                style={{
                                    fontSize: "0.7rem",
                                    fontWeight: 700,
                                    marginTop: -5,
                                    color: "red",
                                }}
                            >
                                {state.count_outgoing_dept}
                            </span>
                        </Row>
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <CorrespondenceTable defaultDepartment={false} />
                </TabPane>
                <TabPane tabId="2">
                    <CorrespondenceDeptTable defaultDepartment={true} />
                </TabPane>
            </TabContent>
        </div>
    );
};

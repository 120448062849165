/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import AddIcon from "@material-ui/icons/Add";
import PrintIcon from "@material-ui/icons/Print";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";

import { useSaveDocuments } from "../hooks/useSaveDocuments";
import { useAllEmployees } from "../hooks/useAllEmployees";
import { useDepartments } from "../hooks/useDepartments";
import { useCategory } from "../../../Manager/hooks/useCategory";
import { useType } from "../../../Manager/hooks/useType";
import { useAction } from "../../../Manager/hooks/useAction";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useCurrentDepartment } from "../hooks/useCurrentDepartment";

import { toastify } from "../../../../components/Toastr";
import { EnchancedModal } from "../../../../components/Modal/EnhancedModal";
import { Context } from "../../../../context/Context";
import Swal from "sweetalert2";
import Checkbox from "@material-ui/core/Checkbox";

interface Props {
    isDept: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const url: any = process.env.REACT_APP_HOST;

export const CorrespondenceDeptModal: React.FC<Props> = () => {
    const { state, dispatch } = useContext(Context);
    const { data = [], isLoading } = useAllEmployees();
    const { data: dataDepartment = [], isLoading: isLoadingDepartment } = useDepartments();
    const { data: dataCategory = [], isLoading: isLoadingCategory } = useCategory();
    const { data: dataType = [], isLoading: isLoadingType } = useType();
    const { data: dataAction = [], isLoading: isLoadingAction } = useAction();

    const [correspondence, setCorrespondence] = useState<any>({
        source: "",
        source_id: "",
        document_type: "",
        category: "",
        subject_matter: "",
        purpose: "",
        amount: "0",
        route: [],
        createdBy: state.employee_id,
        isDept: 1,
        from: "",
    });

    const [files, setFiles] = useState<any>([]);
    const [isPrint, setPrint] = useState<any>(false);
    const [open, setOpen] = React.useState(false);
    const [disabled, setDisabled] = React.useState(false);
    const [create] = useSaveDocuments(setDisabled, setOpen);
    useEffect(() => {
        setDisabled(false);
    }, [disabled]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handlePrintOpen = () => {
        Swal.fire({
            title: "Oooopps",
            text: "This feature is unavailable for this moment.",
            icon: "warning",
        });
    };

    const handleClose = () => {
        setOpen(false);
        setFiles("");
    };

    const handleChange =
        (name) =>
        ({ target: { value } }) => {
            setCorrespondence({ ...correspondence, [name]: value });
        };

    const handleSelectRoute = (event) => {
        let route = [];
        event.map((row: any) => {
            let status = "Not yet acknowledged";
            if (route.length > 0) {
                status = "Pending";
            }
            route.push({
                label: row.label,
                value: row.value,
                department: row.department.DepartmentID,
                status: status,
            });
        });

        setCorrespondence({
            ...correspondence,
            route: route,
            source: state.department_name_action || state.department_name,
            source_id: state.department_id_action || state.department_id,
        });
    };

    const handleFile = (event) => {
        setFiles(event.target.files);
    };

    const handlePrint = (event) => {
        setPrint(event.target.checked);
        setCorrespondence({
            ...correspondence,
            isPrint: event.target.checked,
        });
        setFiles("");
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const lists = [...files];

        if (correspondence.route.length === 0) {
            return toastify(199, "Route's is required.");
        } else if (!correspondence.document_type) {
            return toastify(199, "Document type is required.");
        } else if (!correspondence.category) {
            return toastify(199, "Category is required.");
        } else if (!correspondence.subject_matter) {
            return toastify(199, "Subject matter is required.");
        } else if (!correspondence.amount) {
            return toastify(199, "Amount is required.");
        } else if (!correspondence.purpose) {
            return toastify(199, "Purpose/Action is required.");
        } else if (!lists.length && !isPrint) {
            return toastify(199, "Upload files is required.");
        } else {
            setDisabled(true);
            const formData = new FormData();
            const files = lists;

            formData.append("data", JSON.stringify(correspondence));
            for (let i = 0; i <= files.length; i++) {
                formData.append("files", files[i]);
            }

            create.mutate(formData);

            // setOpen(false);

            setCorrespondence({
                ...correspondence,
                document_type: "",
                category: "",
                subject_matter: "",
                purpose: "",
                amount: "0",
                route: [],
                from: "",
                isPrint: false,
            });

            setFiles("");
            setPrint(false);
        }
    };

    const customStyles = {
        container: (styles) => ({
            ...styles,
            marginTop: 20,
        }),
    };
    const customStylesHeader = {
        container: (styles) => ({
            ...styles,
            marginTop: 20,
        }),
    };

    const ListItem = () => {
        const lists = [...files];
        return (
            <ol>
                {lists.map((item: any, index: number) => (
                    <li key={index} style={{ padding: 5 }}>
                        {item.name}
                    </li>
                ))}
            </ol>
        );
    };

    const ar = data?.data;
    const employeeList = ar?.sort((a: any, b: any) => a.EmployeeName.localeCompare(b.EmployeeName));
    useEffect(() => {
        if (!state.department_id_action) {
            dispatch({
                type: "OUTGOING_DEPARTMENT_ACTION",
                department_name_action: state.department_name,
                department_id_action: state.department_id,
                department_subunit_action: state.isSubUnit,
            });
        }
    }, [employeeList]);

    return (
        <div>
            <Tooltip title="Add">
                <IconButton aria-label="add" onClick={handleClickOpen}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Print">
                <IconButton aria-label="print" onClick={handlePrintOpen}>
                    <PrintIcon />
                </IconButton>
            </Tooltip>
            <EnchancedModal
                open={open}
                setOpen={setOpen}
                title={"Add Correspondence"}
                subtitle={"Fill up correspondence details."}
                view={false}
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                overflow={"auto"}
                position={""}
                display={"block"}
            >
                {!isLoading ? (
                    <Select
                        styles={customStylesHeader}
                        isMulti
                        name="employees"
                        options={employeeList.map((row: any, index) => ({
                            value: row.EmployeeID,
                            label: row.EmployeeName,
                            department: dataDepartment.find((rs: any) => {
                                if (state.department_subunit_action) {
                                    if (rs.DepartmentName === row.SubunitName) {
                                        return rs.DepartmentName;
                                    }
                                } else {
                                    if (rs.DepartmentName === row.SubunitName) {
                                        return rs.DepartmentName;
                                    } else if (
                                        rs.parent_name === row.SubunitName &&
                                        rs.parent_id === row.SubunitID &&
                                        rs.parent_id !== null
                                    ) {
                                        return rs.DepartmentName;
                                    } else if (
                                        rs.DepartmentName === row.DepartmentName &&
                                        rs.DepartmentID === row.DepartmentID
                                    ) {
                                        return rs.DepartmentName;
                                    }
                                }
                            }),
                            id: index,
                        }))}
                        getOptionLabel={(option) => {
                            return state.department_subunit_action
                                ? `${option.label} - ${option.department.DepartmentName}_${option.department.CampusName}`
                                : `${option.department.parent_name}`
                                ? `${option.label} - ${option.department.DepartmentName}_${option.department.CampusName}`
                                : `${option.label} - ${option.department.parent_name}_${option.department.CampusName}`;
                        }}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder={"Select Route's..."}
                        onChange={handleSelectRoute}
                    />
                ) : (
                    <Select
                        styles={customStyles}
                        isMulti
                        name="colors"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder={"Fetching Route..."}
                        onChange={handleSelectRoute}
                    />
                )}

                {!isLoadingType ? (
                    <Select
                        styles={customStyles}
                        className="basic-single"
                        classNamePrefix="select"
                        name="type"
                        options={dataType.map((row) => ({
                            value: row.id,
                            label: row.name,
                        }))}
                        placeholder={"Select Document Type"}
                        onChange={(event) => {
                            setCorrespondence({
                                ...correspondence,
                                document_type: event ? event.label : null,
                            });
                        }}
                        isClearable={true}
                    />
                ) : (
                    <Select
                        styles={customStyles}
                        className="basic-single"
                        classNamePrefix="select"
                        name="type"
                        placeholder={"Fetching Category..."}
                    />
                )}
                {!isLoadingCategory ? (
                    <Select
                        styles={customStyles}
                        className="basic-single"
                        classNamePrefix="select"
                        name="type"
                        options={dataCategory.map((row) => ({
                            value: row.id,
                            label: row.name,
                        }))}
                        placeholder={"Select Category"}
                        onChange={(event) => {
                            setCorrespondence({
                                ...correspondence,
                                category: event ? event.label : null,
                            });
                        }}
                        isClearable={true}
                    />
                ) : (
                    <Select
                        styles={customStyles}
                        className="basic-single"
                        classNamePrefix="select"
                        name="type"
                        placeholder={"Select Category"}
                    />
                )}

                {!isLoadingAction ? (
                    <Select
                        styles={customStyles}
                        className="basic-single"
                        classNamePrefix="select"
                        name="purpose"
                        options={dataAction.map((row) => ({
                            value: row.id,
                            label: row.name,
                        }))}
                        placeholder={"Select Purpose/Action Required"}
                        onChange={(event) => {
                            setCorrespondence({
                                ...correspondence,
                                purpose: event ? event.label : null,
                            });
                        }}
                        isClearable={true}
                    />
                ) : (
                    <Select
                        styles={customStyles}
                        className="basic-single"
                        classNamePrefix="select"
                        name="purpose"
                        placeholder={"Fetching Purpose/Actions..."}
                    />
                )}

                {!isLoadingDepartment ? (
                    <Select
                        styles={customStyles}
                        className="basic-single"
                        classNamePrefix="select"
                        name="department"
                        options={dataDepartment.map((row) => ({
                            value: row.DepartmentID,
                            label: row.DepartmentName + "-" + row.CampusName,
                        }))}
                        placeholder={"Select Requesting Department"}
                        getOptionLabel={(option) => {
                            return `${option.label}`;
                        }}
                        onChange={(event) => {
                            setCorrespondence({
                                ...correspondence,
                                from: event ? event.label : null,
                            });
                        }}
                        isClearable={true}
                    />
                ) : (
                    <Select
                        styles={customStyles}
                        className="basic-single"
                        classNamePrefix="select"
                        name="type"
                        placeholder={"Select Category"}
                    />
                )}
                <TextField
                    margin="dense"
                    label="Subject Matter"
                    type="Subject_Matter"
                    fullWidth
                    value={correspondence.subject_matter}
                    onChange={handleChange("subject_matter")}
                />

                <TextField
                    margin="dense"
                    label="Amount"
                    type="number"
                    fullWidth
                    value={correspondence.amount}
                    onChange={handleChange("amount")}
                />
                <span style={{ color: "gray" }}>
                    Printed File?
                    <Checkbox
                        style={{ float: "inline-start" }}
                        value={correspondence.isPrint}
                        inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                        checked={isPrint}
                        onChange={handlePrint}
                    />
                </span>
                <Button variant="contained" disabled={isPrint} component="label" style={{ marginTop: 10 }}>
                    Upload File
                    <input
                        accept=".doc,.docx,application/pdf,application/msword,
                            application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        type="file"
                        hidden
                        onChange={handleFile}
                        multiple
                    />
                </Button>
                <InputLabel style={{ marginTop: 10, color: "red" }}>
                    {files.length > 0 ? <ListItem /> : "No file is attached."}
                </InputLabel>
            </EnchancedModal>
        </div>
    );
};

import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";

import { EnchancedModal } from "../../../components/Modal/EnhancedModal";
import { useEmployees } from "../../Correspondence/Outgoing/hooks/useEmployees";
import { useEmployeeDetails } from "../../Correspondence/Outgoing/hooks/useEmployeeDetails";
import { useDepartments } from "../../Correspondence/Outgoing/hooks/useDepartments";
import { useSaveUser } from "../../Users/hooks/useSaveUser";

import { Context } from "../../../context/Context";
import { useLocation } from "react-router-dom";

interface EmployeeProps {
    EmployeeID: number;
    EmployeeName: string;
    DepartmentID: number;
    SubunitID: number;
}

interface DepartmentProps {
    DepartmentID: number;
    DepartmentName: string;
    CampusName: string;
}

interface EmployeeeDetailsProps {
    EmployeeID: number;
    LastName: string;
    FirstName: string;
    MiddleName: string;
    Email: string;
}

interface UserTypesProps {
    label: string;
    value: string;
}

interface EmployeeTypesProps {
    department: DepartmentProps;
    employeeDetails: EmployeeeDetailsProps;
    label: string;
    value: number;
}

interface UserState {
    info: any;
    type: string;
    createdBy: number;
    is_SA: number;
    dept_details: any;
}

const UserTypes = [
    {
        label: "Admin",
        value: "admin",
    },
    {
        label: "User",
        value: "user",
    },
];

export const UserModal: React.FC = () => {
    const location = useLocation();
    const [create] = useSaveUser();
    const { state } = useContext(Context);
    const { data: dataEmployees, isLoading: isLoadingEmployees } = useEmployees();
    const { data: dataDepartment, isLoading: isLoadingDepartment } = useDepartments();
    const { data: dataEmployeeDetails, isLoading: isLoadingEmployeeDetails } = useEmployeeDetails();
    const [user, setUser] = useState<UserState>({
        info: [],
        type: "User",
        createdBy: state.employee_id,
        is_SA: 0,
        dept_details: [],
    });

    const [open, setOpen] = useState(false);
    const [employees, setEmployees] = useState([]);

    const customStyles = {
        container: (styles: any) => ({
            ...styles,
            marginTop: 20,
            marginBottm: 20,
            height: 35,
        }),
    };

    const handleSelect = (event: EmployeeTypesProps) => {
        setUser({
            ...user,
            info: event,
        });
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleType = (event: UserTypesProps) => {
        setUser({
            ...user,
            type: event.label,
            is_SA: 1,
        });
    };
    const handleDepartment = (event: DepartmentProps) => {
        setUser({
            ...user,
            dept_details: event,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        create.mutate(user);
        setOpen(false);
    };

    useEffect(
        () => {
            if (!isLoadingEmployees) {
                let filterEmp =
                    state.role === "Admin"
                        ? dataEmployees.data.filter((row: EmployeeProps) => state.department_id === row.DepartmentID)
                        : dataEmployees.data;
                setEmployees(filterEmp);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dataEmployees]
    );

    return (
        <div>
            <Tooltip title="Add">
                <IconButton aria-label="add" onClick={handleClickOpen}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
            <EnchancedModal
                open={open}
                setOpen={setOpen}
                title={"Account"}
                subtitle={"Add default users."}
                view={false}
                height={400}
                width={500}
                overflow={"hidden"}
                handleClose={handleClose}
                handleSubmit={handleSubmit}
            >
                {!isLoadingEmployees && !isLoadingEmployeeDetails ? (
                    <Select
                        styles={customStyles}
                        name="employees"
                        options={employees.map((row: EmployeeProps) => ({
                            value: row.EmployeeID,
                            label: row.EmployeeName,
                            department: dataDepartment.find(
                                (rs: DepartmentProps) =>
                                    rs.DepartmentID === (row.SubunitID ? row.SubunitID : row.DepartmentID)
                            ),
                            employeeDetails: !isLoadingEmployeeDetails
                                ? dataEmployeeDetails.find(
                                      (rs: EmployeeeDetailsProps) => rs.EmployeeID === row.EmployeeID
                                  )
                                : "",
                            // isDisabled: dataUser.find((rs: any) => {
                            //     if (rs.login_id === row.EmployeeID) {
                            //         return true;
                            //     }
                            //     return false;
                            // }),
                        }))}
                        getOptionLabel={(option) => {
                            return `${option.label}`;
                        }}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder={"Search Employee's"}
                        onChange={handleSelect}
                        menuPlacement="auto"
                    />
                ) : (
                    <Select
                        styles={customStyles}
                        name="employees"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder={"Fetching Employee's"}
                        onChange={handleSelect}
                    />
                )}
                {!isLoadingDepartment ? (
                    <Select
                        styles={customStyles}
                        options={dataDepartment.map((row: DepartmentProps) => ({
                            value: row.DepartmentID,
                            label: row.DepartmentName + "-" + row.CampusName,
                            department_name: row.DepartmentName,
                            campus_name: row.CampusName,
                        }))}
                        name="department_type"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder={"Search Department"}
                        onChange={handleDepartment}
                        menuPlacement="auto"
                    />
                ) : (
                    <Select
                        styles={customStyles}
                        name="department_type"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder={"Fetching Department"}
                        onChange={handleSelect}
                    />
                )}
                {state.role === "Super Admin" && location.pathname === "/superadmin/admin" ? (
                    <Select
                        styles={customStyles}
                        options={UserTypes}
                        name="user_type"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder={"Search Type's"}
                        onChange={handleType}
                        maxMenuHeight={60}
                        menuPlacement="auto"
                    />
                ) : (
                    ""
                )}
            </EnchancedModal>
        </div>
    );
};

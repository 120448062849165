import axios from "axios";
import { useContext } from "react";
import { useQuery } from "react-query";
import { Context } from "../../../context/Context";

export const fetchAction = async (data) => {
  const results = await axios
    .get(`${process.env.REACT_APP_HOST}/api/action/all`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + data.queryKey[1],
      },
    })
    .then((res) => res.data.data);

  return results;
};

export const useAction = () => {
  const { state } = useContext(Context);

  return useQuery(["manager_action", state.token], fetchAction);
};
